import React, { useState, useEffect } from 'react'
import {
  Button, Typography,
  InputLabel, Select, MenuItem,
  Switch,
} from '@material-ui/core'
import ImageField from '../../../baseComponents/ImageField'
import TextField from '../../../baseComponents/TextField'

const Billboard = ({
  id = '',
  FieldKey = '',
  GameFieldName = '',
  GameValueImage = '',
  GameValueVideo = '',
  GameValueLink = '',
  GameType = '',
  GameAvailable = false,
  onChange = () => null
}) => {
  const [FieldName, setFieldName] = useState('')
    , [Image, setImage] = useState('')
    , [Video, setVideo] = useState('')
    , [Link, setLink] = useState('')
    , [Type, setType] = useState('')
    , [Available, setAvailable] = useState(false)
    , [touched, setTouched] = useState(false)
  const onFormFieldChange = value => {
    let key = Object.keys(value)[0]
    setTouched(true)
    switch (key) {
      case 'FieldName': return setFieldName(value['FieldName'])
      case 'Type': return setType(value['Type'])
      case 'Image': return setImage(value['Image'])
      case 'Video': return setVideo(value['Video'])
      case 'Link': return setLink(value['Link'])
      case 'Available': return setAvailable(value['Available'])
      default: return
    }
  }
  const onSaveChanges = () => {
    setTouched(false)
    onChange({
      [FieldKey]: {
        Image,
        Video,
        Link,
        FieldName,
        Type,
        Available
      }
    })
  }

  useEffect(() => {
    setFieldName(GameFieldName)
    setImage(GameValueImage)
    setVideo(GameValueVideo)
    setLink(GameValueLink)
    setType(GameType)
    setAvailable(GameAvailable)
  }, [GameFieldName, GameValueImage, GameValueVideo, GameValueLink, GameType, GameAvailable])


  return <div style={{ width: '100%', marginBottom: '2vh' }}>
    <Typography style={{ marginTop: '2vh' }}>Identificador en app: {FieldKey}</Typography>
    <div className='double-field'>
      <TextField fullWidth margin='normal' label='Nombre del campo'
        value={FieldName}
        onChange={e => onFormFieldChange({ FieldName: e.target.value })}
        placeholder='Campo de texto' />

      <TextField fullWidth margin='normal' label='Enlance'
        value={Link}
        onChange={e => onFormFieldChange({ Link: e.target.value })}
        placeholder='Campo de texto' style={{ marginLeft: '2rem' }} />

      <div className='field field-margin-top' style={{ marginLeft: '2rem' }}>
        <InputLabel id='availability' className='label-margin'>Tipo de campo</InputLabel>
        <Select fullWidth labelId='availability' id='select' value={Type} onChange={e => onFormFieldChange({ Type: e.target.value })}>
          <MenuItem value='image'>Imagen</MenuItem>
          <MenuItem value='video'>Video</MenuItem>
        </Select>
      </div>
    </div>
    <div className='field field-margin-top'>
      <InputLabel className='label-margin'>Imagen activa</InputLabel>
      <div style={{ alignItems: 'baseline', display: 'flex' }}>
        <InputLabel>No</InputLabel>
        <Switch
          checked={Available}
          onChange={() => onFormFieldChange({ Available: !Available })} />
        <InputLabel>Sí</InputLabel>
      </div>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {
        Type === 'image'
          ? <ImageField
            id={id}
            folder='game'
            label={`Imagen de ${FieldName}`}
            onChange={url => onFormFieldChange({ Image: url })}
            placeholder='https://hoppia.s3.us-west-2.amazonaws.com/game/z-999989/game.png'
            value={Image}
          />
          : <TextField fullWidth margin='normal' label='Url del video'
            value={Video}
            onChange={e => onFormFieldChange({ Video: e.target.value })}
            placeholder='Campo de texto' />
      }
    </div>

    <div>
      {
        touched
          ? <Button onClick={onSaveChanges} variant='contained' color='primary'>
            <Typography>Guardar cambios</Typography>
          </Button>
          : null
      }
    </div>
  </div>
}

export default Billboard