import React, { useState, useEffect } from 'react'
import { Button, Paper, Typography, Modal, Slider } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { useStyles } from './styles'
import { getUrl } from '../../utils/routes'

import Loader from '../../baseComponents/Loader'
import EventBar from './components/EventBar'

import './index.css'
import EventsTable from './components/Table'

const Events = props => {
  const [prodEvents, setProdEvents] = useState([])
    , [devEvents, setDevEvents] = useState([])
    , [touched, setTouched] = useState(false)
    , [touchedEvents, setTouchedEvents] = useState({})
    , [isFetching, setIsFetching] = useState(true)
    , [showModal, setShowModal] = useState(false)
    , [sliderValue, setSliderValue] = useState(0)
    , [eventTouched, setEventTouched] = useState(null)
    , classes = useStyles()

  const onSwitch = (event) => {
    let SortIndex = event.SortIndex
    event.IsReady = !event.IsReady
    event.SortIndex = event.IsReady ? prodEvents.length + 1 : -1
    if (event.IsReady) {
      let eventList = devEvents
        .filter(evt => evt.id !== event.id)

      setProdEvents([...prodEvents, event])
      setDevEvents([...eventList])

      let touchedEventList = { ...touchedEvents }
      touchedEventList[event.id] = { ...event }
      delete touchedEventList[event.id].id

      setTouchedEvents(touchedEventList)
    } else {
      let eventList = prodEvents
        .filter(evt => evt.id !== event.id)
        .map(evt => ({ ...evt, SortIndex: evt.SortIndex < SortIndex ? evt.SortIndex : evt.SortIndex - 1 }))
      setProdEvents(eventList)
      setDevEvents([...devEvents, event])

      let touchedEventList = { ...touchedEvents }
      touchedEventList[event.id] = { ...event }
      delete touchedEventList[event.id].id

      for (let prodEvent of eventList) {
        if (prodEvent.SortIndex >= SortIndex) {
          touchedEventList[prodEvent.id] = { ...prodEvent }
          delete touchedEventList[prodEvent.id].id
          console.log(prodEvent.Title)
        }
      }
      setTouchedEvents(touchedEventList)
    }
    setTouched(true)
  }
  const onSubmit = async () => {
    let eventList = Object.keys(touchedEvents)
    for (let event of eventList) {
      let payload = { ...touchedEvents[event] }
      const eventRef = fbDatabase.ref('EVENTS').child(event)
      await eventRef.set({
        ...payload
      })
    }
    setTouchedEvents({})
    setTouched(false)
  }

  const onChangeValue = event => {
    setEventTouched(event)
    setSliderValue(event.SortIndex)
    setShowModal(true)
  }

  const onChangePosition = () => {
    let eventList = prodEvents.filter(evt => evt.id !== eventTouched.id)
      .map((evt, index) => {


        return { ...evt, SortIndex: index + 1 }
      })
      .sort((a, b) => a.SortIndex - b.SortIndex)

    eventList = [...eventList, eventTouched]
      .map((evt, index) => {
        if (evt.id === eventTouched.id)
          return { ...evt, SortIndex: sliderValue }
        let SortIndex = evt.SortIndex >= sliderValue ? evt.SortIndex + 1 : evt.SortIndex
        return { ...evt, SortIndex }
      })
      .sort((a, b) => a.SortIndex - b.SortIndex)

    let touchedEventList = { ...touchedEvents }

    for (let prodEvent of eventList) {
      touchedEventList[prodEvent.id] = { ...prodEvent }
      delete touchedEventList[prodEvent.id].id
    }

    setProdEvents(eventList)
    setTouchedEvents(touchedEventList)
    setTouched(true)
    setShowModal(false)
  }

  const onEditEvent = id => {
    props.history.push(`${getUrl('event')}/${id}`)
  }

  useEffect(() => {
    const eventsRef = fbDatabase.ref('EVENTS')
    eventsRef.once('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []
      eventSnapshot = { ...eventSnapshot, ...touchedEvents }

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      let prodEventList = eventList.filter(evt => evt.IsReady)
        .sort((a, b) => a.SortIndex - b.SortIndex)
        , devEventList = eventList.filter(evt => !evt.IsReady)

      setDevEvents(devEventList)
      setProdEvents(prodEventList)
      setIsFetching(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetching) return <Loader />

  return <div id='events'>
    {
      touched
        ? <EventBar onSubmit={onSubmit} />
        : null
    }
    <Paper className={`${classes.status} ${classes.prodBanner}`}>
      <Typography component='h5' >
        En producción
      </Typography>
    </Paper>
    <div className='event-list'>
      <EventsTable rows={prodEvents} onSwitch={onSwitch} onChangeValue={onChangeValue} onEdit={onEditEvent} />
    </div>
    <Paper className={`${classes.status} ${classes.devBanner}`}>
      <Typography component='h5' >
        En desarrollo
      </Typography>
    </Paper>
    <div className='event-list'>
      <EventsTable rows={devEvents} disableSort={true} onSwitch={onSwitch} onEdit={onEditEvent} />
    </div>
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <div className='modal-center'>
        <Paper className='slider-position'>
          <Typography id="non-linear-slider" gutterBottom>
            Posición
          </Typography>
          <Slider
            min={1}
            max={prodEvents.length}
            className={classes.slider}
            onChange={(_, value) => setSliderValue(value)}
            value={sliderValue}
            valueLabelDisplay='on'
          />
          <Button onClick={onChangePosition} className={classes.addEventBtn} variant='outlined'>
            <Typography>Aceptar</Typography>
          </Button>
        </Paper>
      </div>
    </Modal>
  </div>
}

export default Events