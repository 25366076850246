import React, { useState, useEffect } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { getUrl } from '../../utils/routes'
import { useStyles } from './styles'
import { generateString } from '../../utils/misc'

import Loader from '../../baseComponents/Loader'
import EventItem from './components/EventItem'
import EventBar from './components/EventBar'

import './Events.css'

const Events = props => {
  const [events, setEvents] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , classes = useStyles()

  const onEditList = () => {
    props.history.push(getUrl('editEvents'))
  }

  const onShowForm = () => {
    props.history.push(`${getUrl('event')}/${generateString(10)}`)
  }

  useEffect(() => {
    const eventsRef = fbDatabase.ref('EVENTS')
    eventsRef.on('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      // eventList = eventList.sort((a, b) => a.id - b.id)
      setEvents(eventList)
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return <Loader />

  /*   if (isEventFormActive) {
      let lastId = events.map(evt => {
        let idNumber = evt.id.split('-')[1]
        return Number(idNumber)
      }).sort((a, b) => a - b)[0]
  
      lastId = `z-${--lastId}`
  
      return <Container maxWidth='xl'>
        <EventForm {...props} isNewEvent={true} id={lastId}
          onSubmit={() => setIsEventFormActive(false)}
          onCancel={() => setIsEventFormActive(false)} />
      </Container>
    } */

  let prodEvents = events.filter(evt => evt.IsReady)
    .sort((a, b) => a.SortIndex - b.SortIndex)
    , devEvents = events.filter(evt => !evt.IsReady)

  return <div id='events'>
    <EventBar onShowForm={onShowForm} onEditList={onEditList} />
    <Paper className={`${classes.status} ${classes.prodBanner}`}>
      <Typography component='h5' >
        En producción
      </Typography>
    </Paper>
    <div className='event-list'>
      {
        prodEvents.map(event => <EventItem key={event.id} event={event} />)
      }
    </div>
    <Paper className={`${classes.status} ${classes.devBanner}`}>
      <Typography component='h5' >
        En desarrollo
      </Typography>
    </Paper>
    <div className='event-list'>
      {
        devEvents.map(event => <EventItem key={event.id} event={event} />)
      }
    </div>
  </div>
}

export default Events