import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import { useStyles } from '../styles'

const Header = ({ isNewAvatar = true, id = '' }) => {
  const clasess = useStyles()

  return <div className='header'>
    <Typography component='h2' variant='h4' >{isNewAvatar ? 'Crear avatar' : 'Actualizar avatar'}</Typography>
    <Paper className={clasess.headerId}>
      <Typography component='h5'>ID: {id}</Typography>
    </Paper>
  </div>
}

export default Header