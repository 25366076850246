import React from 'react'
import { Fab, Paper, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { getUrl } from '../../../utils/routes'

const EventItem = ({ event, history }) => {
  const onClickHandler = () => {
    history.push(`${getUrl('event')}/${event.id}`)
  }

  return <div className='event-item'>
    <Paper className='event-paper' style={{ background: `url('${event.PosterURL}')` }}>
      <div className='event-container'>
        <Typography className='event-title' component='h2' variant='h4'>{event.Title}</Typography>
        <Typography component='h3' variant='h6'>
          {event.Details}
        </Typography>
        <Fab className='btn-event' color='default' size='medium' onClick={onClickHandler}>
          <ChevronRight />
        </Fab>
      </div>
    </Paper>
  </div>
}

export default withRouter(EventItem)