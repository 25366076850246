export const initialState = {
  AndroidVersion: '0.0.0',
  AndroidURL: '',
  IOSVersion: '0.0.0',
  IOSURL: '',
  MacOsVersion: '0.0.0',
  MacOsURL: '',
  WindowsVersion: '0.0.0',
  WindowsURL: ''
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'AndroidVersion': return { ...state, AndroidVersion: action.value }
    case 'AndroidURL': return { ...state, AndroidURL: action.value }
    case 'IOSVersion': return { ...state, IOSVersion: action.value }
    case 'IOSURL': return { ...state, IOSURL: action.value }
    case 'MacOsVersion': return { ...state, MacOsVersion: action.value }
    case 'WindowsVersion': return { ...state, WindowsVersion: action.value }
    case 'MacOsURL': return { ...state, MacOsURL: action.value }
    case 'WindowsURL': return { ...state, WindowsURL: action.value }
    case 'appSettings': return { ...state, ...action.value }
    default: return initialState
  }
}