import React from 'react'
import { Paper, Switch, InputLabel } from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'
import ImageField from './ImageField'
import { useStyles } from '../styles'

const BasicInfo = ({
  id,
  values,
  defaultValues,
  onFormChange,
}) => {
  const classes = useStyles()
  return <Paper className={classes.form}>
    <div className='field'>
      <TextField fullWidth margin='normal' label='Título' value={values.Title}
        onChange={e => onFormChange('Title', e.target.value)}
        placeholder={defaultValues.Title} />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Detalles' value={values.Details}
        onChange={e => onFormChange('Details', e.target.value)}
        placeholder={defaultValues.Details}
      />
    </div>

    <div className='field field-margin-top'>
      <InputLabel className='label-margin'>Isla disponible</InputLabel>
      <div style={{ alignItems: 'baseline', display: 'flex' }}>
        <InputLabel>No</InputLabel>
        <Switch checked={values.Available} onChange={() => onFormChange('Available', !values.Available)} />
        <InputLabel>Sí</InputLabel>
      </div>
    </div>

    <div className='field'>
      <ImageField
        id={id}
        label='Mapa'
        onChange={url => onFormChange('Map', url)}
        placeholder={defaultValues.Map}
        value={values.Map}
      />
    </div>

  </Paper>
}

export default BasicInfo