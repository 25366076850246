import React, { useState, useReducer, useEffect } from 'react'
import { Button, Typography } from '@material-ui/core'
import { initialState, reducer } from './formReducer'
import { formValues } from './defaultValues'
import { useStyles } from './styles'
import { fbDatabase } from '../../services/firebase'
import Header from './components/Header'
import BasicInfo from './components/BasicInfo'
import Loading from '../../baseComponents/Loader'

import './index.css'

const IslandForm = ({
  eventId = '',
  id = '1111',
  isNewBoutique = false,
  boutique = null,
  onSubmit = () => null,
  onCancel
}) => {

  const [state, dispatch] = useReducer(reducer, initialState)
    , [isSaving, setIsSaving] = useState(false)
    , classes = useStyles()
    , onFormChange = (type, value) => { dispatch({ type, value }) }
    , onSubmitboutique = async () => {
      setIsSaving(true)

      let payload = { ...state }

      if (isNewBoutique) {
        payload.Created_At = Date.now()
      }

      payload.LastUpdate = Date.now()

      let boutiqueRef = fbDatabase.ref('BOUTIQUES').child(id)
      await boutiqueRef.set(payload)
      onSubmit(payload)
    }


  useEffect(() => {
    if (boutique) {
      dispatch({ type: 'setBoutique', boutique: boutique })
    }
    dispatch({ type: 'setBoutique', boutique: { EventId: eventId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boutique])

  if (isSaving) return <Loading />

  return <form onSubmit={onSubmitboutique} id='boutique-form'>
    <Header isNewBoutique={isNewBoutique} id={id} />
    <div className={classes.root}>
      <BasicInfo id={id} defaultValues={formValues} isNewBoutique={isNewBoutique}
        onFormChange={onFormChange} values={state} />
    </div>


    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitboutique} variant='contained' color='primary'>
      <Typography>
        {isNewBoutique ? 'Crear' : 'Actualizar'}
      </Typography>
    </Button>

    {
      typeof onCancel === 'function'
        ? <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
          <Typography>Cancelar</Typography>
        </Button>
        : null
    }
  </form>
}

export default IslandForm
