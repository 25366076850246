import React, { useContext, useState, useEffect } from 'react'
import { useStyles } from '../../utils/styles'
import { fbDatabase } from '../../services/firebase'

import Header from '../../baseComponents/FormHeader'
import { Paper, Button, Typography, InputLabel, Select, MenuItem } from '@material-ui/core'
import { EventInfoContext } from '../../providers/EventInfoProvider'


const Venue = props => {
  let { eventId } = props.match.params

  const context = useContext(EventInfoContext)
    , { state, onFormChange, onSave, onCancel, } = context
    , classes = useStyles()

  const [venues, setVenues] = useState([])
    , [selectedVenue, setSelectedVenue] = useState('0')
    , onAddVenue = e => {
      setSelectedVenue(e.target.value)
      onFormChange('Venue', e.target.value)
    }

  useEffect(() => {
    const venuesRef = fbDatabase.ref('VENUES')
    venuesRef.on('value', snapshot => {
      let venuesnapshot = snapshot.val()
        , venuesList = []
      for (let id in venuesnapshot)
        venuesList.push({ id, ...venuesnapshot[id] })

      venuesList = venuesList.sort((a, b) => a.id - b.id)
      setVenues(venuesList)
      setSelectedVenue(state.Venue)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitEvent = (e) => {
    e.preventDefault()
    onSave(() => { })
  }

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header mainTitle='Venue' isNewEvent={false} id={eventId} />
    <Paper className={classes.paper}>
      <div className='add-avatar-bar'>
        <div className='field field-margin-top' style={{ marginBottom: '1rem' }}>
          <InputLabel id='avatar' className='label-margin'>Selecciona el venue</InputLabel>
          <Select value={selectedVenue} fullWidth labelId='avatar' id='avatar' onChange={onAddVenue}>
            {
              venues.map(venue => <MenuItem key={venue.Index} value={venue.Index}>
                <img style={{ width: '2vw', height: '2vw', marginRight: '5px', }} src={venue.Picture} alt={venue.Name} />
                {venue.Name}
              </MenuItem>)
            }
          </Select>
        </div>
      </div>
    </Paper>

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Actualizar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default Venue