import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core'
import { Dashboard, Event, EventSeat, Face, PhoneIphone, VideogameAsset, Info, Group, MeetingRoom, Panorama, VideoLibrary, Description, Landscape, Store, Web } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { getUrl } from '../../../utils/routes'

const MainList = props => {
  let { pathname } = window.location
    , isEventSelected = pathname.search(`${getUrl('event')}/`) !== -1
    , eventId = pathname.replace(`${getUrl('event')}`, '').split('/').filter(param => Boolean(param))

  eventId = eventId[0]

  const onClickHandler = url => {
    if (url !== props.location.pathname)
      props.history.push(url)
  }

  if (isEventSelected) return <List>
    <Tooltip placement='left' arrow title="Home">
      <ListItem button onClick={() => onClickHandler(getUrl('home'))}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Info general">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}`)}>
        <ListItemIcon>
          <Info />
        </ListItemIcon>
        <ListItemText primary='Info general' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Salas">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('rooms')}`)}>
        <ListItemIcon>
          <MeetingRoom />
        </ListItemIcon>
        <ListItemText primary='Salas' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Usuarios">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('users')}`)}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary='Usuarios' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Billboards">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('billboards')}`)}>
        <ListItemIcon>
          <Panorama />
        </ListItemIcon>
        <ListItemText primary='Billboards' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Videos">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('videos')}`)}>
        <ListItemIcon>
          <VideoLibrary />
        </ListItemIcon>
        <ListItemText primary='Videos' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Agenda">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('events')}`)}>
        <ListItemIcon>
          <Event />
        </ListItemIcon>
        <ListItemText primary='Agenda' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Noticias">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('news')}`)}>
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        <ListItemText primary='Noticias' />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Islas">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('islands')}`)}>
        <ListItemIcon>
          <Landscape />
        </ListItemIcon>
        <ListItemText primary="Islas" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Boutiques">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('boutiques')}`)}>
        <ListItemIcon>
          <Store />
        </ListItemIcon>
        <ListItemText primary="Boutiques" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Juegos">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('games')}`)}>
        <ListItemIcon>
          <VideogameAsset />
        </ListItemIcon>
        <ListItemText primary="Juegos" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Venue">
      <ListItem button onClick={() => onClickHandler(`${getUrl('event')}/${eventId}${getUrl('venue')}`)}>
        <ListItemIcon>
          <EventSeat />
        </ListItemIcon>
        <ListItemText primary="Venue" />
      </ListItem>
    </Tooltip>
  </List>


  return <List>
    <Tooltip placement='left' arrow title="Home">
      <ListItem button onClick={() => onClickHandler(getUrl('home'))}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Eventos">
      <ListItem button onClick={() => onClickHandler(getUrl('events'))}>
        <ListItemIcon>
          <Event />
        </ListItemIcon>
        <ListItemText primary="Eventos" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Avatares">
      <ListItem button onClick={() => onClickHandler(getUrl('avatars'))}>
        <ListItemIcon>
          <Face />
        </ListItemIcon>
        <ListItemText primary="Avatares" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="Venues">
      <ListItem button onClick={() => onClickHandler(getUrl('venues'))}>
        <ListItemIcon>
          <EventSeat />
        </ListItemIcon>
        <ListItemText primary="Venues" />
      </ListItem>
    </Tooltip>

    <Tooltip placement='left' arrow title="App settings">
      <ListItem button onClick={() => onClickHandler(getUrl('appSettings'))}>
        <ListItemIcon>
          <PhoneIphone />
        </ListItemIcon>
        <ListItemText primary="App settings" />
      </ListItem>
    </Tooltip>
    <Tooltip placement='left' arrow title="Noticias del sitio">
      <ListItem button onClick={() => onClickHandler(getUrl('siteNews'))}>
        <ListItemIcon>
          <Web />
        </ListItemIcon>
        <ListItemText primary="Noticias del sitio" />
      </ListItem>
    </Tooltip>
  </List>
}
export default withRouter(MainList)