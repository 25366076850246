import React, { useReducer, useEffect } from 'react'
import { Button, Typography } from '@material-ui/core'
import { initialState, reducer } from './formReducer'
import { formValues } from './defaultValues'
import { useStyles } from './styles'
import { fbDatabase } from '../../services/firebase'

import Header from './components/Header'
import BasicInfo from './components/BasicInfo'

import './EventForm.css'

const EventForm = ({
  eventId = 'EventId',
  id = '1111',
  isNewEvent = false,
  event = null,
  onSubmit = () => null,
  onCancel
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
    , classes = useStyles()
    , onFormChange = (type, value) => { dispatch({ type, value }) }
    , onSubmitEvent = () => {
      let payloadState = { ...state, }
      let payload = {
        ...payloadState,
        Title: state.Title.length ? state.Title : formValues.Title,
        PosterURL: state.PosterURL.length ? state.PosterURL : formValues.PosterURL,
        Text: state.Text.length ? state.Text : formValues.Text,
        URL: state.URL.length ? state.URL : formValues.URL,
        EventId: eventId
      }

      const eventRef = fbDatabase.ref('HOPPIA_AGENDA').child(id)
      eventRef.set({
        ...event,
        ...payload
      })
      dispatch({ type: 'reset' })
      onSubmit(payload)
    }

  useEffect(() => {
    if (event) {
      dispatch({ type: 'setEvent', event: event })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header isNewEvent={isNewEvent} id={id} />
    <BasicInfo id={id} defaultValues={formValues} isNewEvent={isNewEvent}
      onFormChange={onFormChange} values={state} />


    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        {isNewEvent ? 'Crear' : 'Actualizar'}
      </Typography>
    </Button>

    {
      typeof onCancel === 'function'
        ? <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
          <Typography>Cancelar</Typography>
        </Button>
        : null
    }
  </form>
}

export default EventForm
