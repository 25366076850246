import React from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, Paper, Typography,
} from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useStyles } from '../styles'

const Versioning = ({
  values,
  defaultValues,
  onFormChange,
}) => {

  const classes = useStyles()

  return <Paper>
    <Accordion className={classes.form}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} >
        <Typography component='h2' variant='h4' >Versionamiento</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Typography component='h3' variant='h5' >Android</Typography>
        <div className='field'>
          <TextField fullWidth margin='normal' label='Versión de Android' value={values.AndroidVersion}
            onChange={e => onFormChange('AndroidVersion', e.target.value)}
            placeholder={defaultValues.AndroidVersion}
          />
        </div>
        <div className='field'>
          <TextField fullWidth margin='normal' label='URL de android' value={values.AndroidURL}
            onChange={e => onFormChange('AndroidURL', e.target.value)}
            placeholder={defaultValues.AndroidURL}
          />
        </div>
        <Typography component='h3' variant='h5' >IOS</Typography>
        <div className='field'>
          <TextField fullWidth margin='normal' label='Versión de IOS' value={values.IOSVersion}
            onChange={e => onFormChange('IOSVersion', e.target.value)}
            placeholder={defaultValues.IOSVersion}
          />
        </div>
        <div className='field'>
          <TextField fullWidth margin='normal' label='URL de IOS' value={values.IOSURL}
            onChange={e => onFormChange('IOSURL', e.target.value)}
            placeholder={defaultValues.IOSURL}
          />
        </div>
        <Typography component='h3' variant='h5' >Mac OS</Typography>
        <div className='field'>
          <TextField fullWidth margin='normal' label='Versión de Mac OS' value={values.MacOsVersion}
            onChange={e => onFormChange('MacOsVersion', e.target.value)}
            placeholder={defaultValues.MacOsVersion}
          />
        </div>
        <div className='field'>
          <TextField fullWidth margin='normal' label='URL de Mac OS' value={values.MacOsURL}
            onChange={e => onFormChange('MacOsURL', e.target.value)}
            placeholder={defaultValues.MacOsURL}
          />
        </div>
        <Typography component='h3' variant='h5' >Windows</Typography>
        <div className='field'>
          <TextField fullWidth margin='normal' label='Versión de Windows' value={values.WindowsVersion}
            onChange={e => onFormChange('WindowsVersion', e.target.value)}
            placeholder={defaultValues.WindowsVersion}
          />
        </div>
        <div className='field'>
          <TextField fullWidth margin='normal' label='URL de Windows' value={values.WindowsURL}
            onChange={e => onFormChange('WindowsURL', e.target.value)}
            placeholder={defaultValues.WindowsURL}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  </Paper>
}

export default Versioning