import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  addEventBtn: {
    color: theme.palette.success.main,
  },
  devBanner: {
    background: theme.palette.error.dark,
    marginBottom: theme.spacing(4),
  },
  prodBanner: {
    background: theme.palette.success.dark,
  },
  status: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(2),
    maxWidth: '20%'
  },
  slider: {
    color: theme.palette.info.dark,
    marginTop: theme.spacing(6),
  },
}))