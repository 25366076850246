import React, { useEffect, useReducer, useState, createContext } from 'react'
import { fbDatabase } from '../../services/firebase'
import { initialState, reducer } from './formReducer'
import { formValues } from './defaultValues'

import Loader from '../../baseComponents/Loader'
import { Container, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { withRouter } from 'react-router-dom'

export const EventInfoContext = createContext({ event: null })

const EventInfoProvider = props => {
  let { eventId } = props.match.params

  const [state, dispatch] = useReducer(reducer, initialState)
    , [event, setEvent] = useState({})
    , [mediaState, setMediaState] = useState({})
    , [mediaGames, setMediaGames] = useState({})
    , [isLoading, setIsLoading] = useState(true)
    , [success, setSuccess] = useState(false)

  const onFormChange = (type, value) => { dispatch({ type, value }) }
    , onMediaFormChange = (value) => {
      setMediaState({ ...mediaState, ...value })
    }
    , onMediaGameFormChange = (value) => {
      setMediaGames({ ...mediaGames, ...value })
    }
    , onCancel = () => {
      dispatch({ type: 'setEvent', event: event })
      if (event && event.__Media) setMediaState(event.__Media)
      if (event && event.__MediaGame) setMediaGames(event.__MediaGame)
    }
    , onSave = (cb = () => null) => {
      let payloadState = { ...state, }

      if (Object.keys(mediaState).length) {
        payloadState = { ...payloadState, __Media: mediaState }
        let MediaData = { ...mediaState }

        for (let key of Object.keys(MediaData)) {
          MediaData[key] = {
            Type: MediaData[key].Type,
            MediaURL: MediaData[key].Type === 'image' ? MediaData[key].Image : MediaData[key].Video,
            Link: MediaData[key].Link,
            Available: MediaData[key].Available
          }
        }
        payloadState = { ...payloadState, Media: MediaData }
      }

      if (Object.keys(mediaGames).length) {
        payloadState = { ...payloadState, __MediaGame: mediaGames }
        let gameData = Object.assign({}, mediaGames)
        for (let gameId of Object.keys(gameData)) {
          let gamePayload = Object.assign({}, gameData[gameId])
          let media = Object.assign({}, gamePayload.Media)
          for (let mediaId of Object.keys(media)) {
            media[mediaId] = {
              Type: media[mediaId].Type,
              MediaURL: media[mediaId].Type === 'image' ? media[mediaId].Image : media[mediaId].Video,
              Link: media[mediaId].Link,
              Available: media[mediaId].Available
            }
          }
          gamePayload.Media = media
          gameData[gameId] = gamePayload
        }
        payloadState = { ...payloadState, Games: gameData }
      }

      let payload = { ...event, ...payloadState }
      const eventRef = fbDatabase.ref('EVENTS').child(eventId)
      eventRef.set({
        ...payload
      })
      setSuccess(true)
    }

  useEffect(() => {
    const eventRef = fbDatabase.ref('EVENTS').child(eventId)
    eventRef.on('value', snapshot => {
      setIsLoading(true)
      let event = snapshot.val()
      if (event) {
        setEvent(event)
        dispatch({ type: 'setEvent', event: event })
        if (event && event.__Media) setMediaState(event.__Media)
        if (event && event.__MediaGame) setMediaGames(event.__MediaGame)
      }
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <Loader />

  return <EventInfoContext.Provider
    value={{
      state,
      mediaState,
      mediaGames,
      formValues,
      onFormChange,
      onMediaFormChange,
      onMediaGameFormChange,
      onSave,
      onCancel,
    }}
  >
    <Container maxWidth='xl'>
      {props.children}
    </Container>
    <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
      <Alert onClose={() => setSuccess(false)} severity="success">
        Se actualizaron los datos
      </Alert>
    </Snackbar>
  </EventInfoContext.Provider>
}

export default withRouter(EventInfoProvider)
