export const formValues = {
  Title: `Boutique`,
  IdEcommerce: '',
  Size: 'S',
  Logo: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/logotienda512.png',
  Roof: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/Marquee_Albedo.jpg',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/Marquee_Albedo.jpg'
  },
  LeftPoster: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/PosterLeftRight_512.jpg',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/PosterLeftRight_256.jpg'
  },
  RightPoster: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/PosterLeftRight_512.jpg',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/PosterLeftRight_256.jpg'
  },
  MainPoster: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/PosterCentral_512.jpg',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/PosterCentral_256.jpg'
  },
  SmallSideboard1: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  SmallSideboard2: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  SmallSideboard3: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  SmallSideboard4: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  SmallSideboard5: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  SmallSideboard6: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  BigSideboard1: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
  BigSideboard2: {
    HD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_512.png',
    SD: 'https://hoppia.s3.us-west-2.amazonaws.com/boutiques/1111/01_tshirt_256.png'
  },
}