import React, { useState, useEffect, useReducer } from 'react'
import { Button, Snackbar, Container, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Versioning from './components/Versioning'
import Loading from '../../baseComponents/Loader'
import { initialState, reducer } from './formReducer'
import { fbDatabase } from '../../services/firebase'
import { formValues } from './defaultValues'

import { useStyles } from './styles'

const AppSettings = props => {
  const classes = useStyles()
  const [state, dispatch] = useReducer(reducer, initialState)
    , [isFetching, setIsFetching] = useState(true)
    , [error, setError] = useState(false)
    , [success, setSuccess] = useState(false)
    , onFormChange = (type, value) => { dispatch({ type, value }) }
    , onSubmitEvent = () => {
      const appSettingsRef = fbDatabase.ref('APPSETTINGS')
      appSettingsRef.set(state)
        .then(res => {
          setSuccess(true)
        })
        .catch(err => {
          console.log(err)
          setError(true)
        })
    }

  useEffect(() => {
    const appSettingsRef = fbDatabase.ref('APPSETTINGS')
    appSettingsRef.on('value', snapshot => {
      dispatch({ type: 'appSettings', value: snapshot.val() })
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return <Loading />

  return <Container maxWidth='lg' className={classes.container}>
    <Versioning defaultValues={formValues} values={state} onFormChange={onFormChange} />
    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Guardar Cambios
      </Typography>
    </Button>
    <Snackbar className={classes.success} open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
      <Alert elevation={6} variant="filled" severity="success">
        <Typography>Cambios guardados</Typography>
      </Alert>
    </Snackbar>
    <Snackbar className={classes.error} open={error} autoHideDuration={3000} onClose={() => setError(false)}>
      <Alert elevation={6} variant="filled" severity="error">
        <Typography>Algo salio mal</Typography>
      </Alert>
    </Snackbar>
  </Container>
}

export default AppSettings