import React, { useState, useEffect } from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { useStyles } from './styles'

import Loader from '../../baseComponents/Loader'
import EventsCard from './components/EventsCard'
import ActiveEvents from './components/ActiveEvents'

const Home = props => {
  const [events, setEvents] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , classes = useStyles()

  useEffect(() => {
    const eventsRef = fbDatabase.ref('EVENTS')

    eventsRef.on('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      eventList = eventList.sort((a, b) => a.id - b.id)
      setEvents(eventList)
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return <Loader />

  return <Container maxWidth='lg' className={classes.container}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3}>
        <EventsCard events={events} />
      </Grid>
      <Grid item xs={12} md={8} lg={4}>
        <ActiveEvents events={events} />
      </Grid>
    </Grid>
    <Box pt={4}>
      <Typography>
        Hoppia
      </Typography>
    </Box>
  </Container>
}

export default Home

/*
 <Grid item xs={12} md={4} lg={3}>
        <EventsCard events={events} />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <EventsCard events={events} />
      </Grid>
      <Grid item xs={12}>
        <EventsCard events={events} />
      </Grid>
*/