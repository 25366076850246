export const initialState = {
  Title: '',
  Availability: false,
  Details: '',
  EndDate: '',
  PosterURL: '',
  StreamURL: '',
  StreamURL2: '',
  StartDate: '',
  IsReady: false,
  MaxPlayersPerRoom: 50,
  LimitReached: false,
  BannerLeftURL: '',
  BannerRightURL: '',
  BannerMainScreenURL: '',
  BannerSmallScreenURL: '',
  MapURL: '',
  VerticalBannerL1: '',
  VerticalBannerL2: '',
  VerticalBannerL3: '',
  VerticalBannerR1: '',
  VerticalBannerR2: '',
  VerticalBannerR3: '',
  WelcomeBanner: '',
  Avatars: [],
  Venue: 0,
  IsPrivate: true,
  AltPosterURL: '',
  PassCodeActive: false,
  PassCode: '',
  Posters: {},
  Rooms: [],
  RoomsLimit: 2,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'Title': return { ...state, Title: action.value }
    case 'Details': return { ...state, Details: action.value }
    case 'StartDate': return { ...state, StartDate: action.value }
    case 'EndDate': return { ...state, EndDate: action.value }
    case 'PosterURL': return { ...state, PosterURL: action.value }
    case 'AltPosterURL': return { ...state, AltPosterURL: action.value }
    case 'StreamURL': return { ...state, StreamURL: action.value }
    case 'StreamURL2': return { ...state, StreamURL2: action.value }
    case 'Availability': return { ...state, Availability: action.value }
    case 'IsReady': return { ...state, IsReady: action.value }
    case 'MaxPlayersPerRoom': return { ...state, MaxPlayersPerRoom: action.value }
    case 'LimitReached': return { ...state, LimitReached: action.value }

    case 'BannerLeftURL': return { ...state, BannerLeftURL: action.value }
    case 'BannerRightURL': return { ...state, BannerRightURL: action.value }
    case 'BannerMainScreenURL': return { ...state, BannerMainScreenURL: action.value }
    case 'BannerSmallScreenURL': return { ...state, BannerSmallScreenURL: action.value }

    case 'VerticalBannerL1': return { ...state, VerticalBannerL1: action.value }
    case 'VerticalBannerL2': return { ...state, VerticalBannerL2: action.value }
    case 'VerticalBannerL3': return { ...state, VerticalBannerL3: action.value }
    case 'VerticalBannerR1': return { ...state, VerticalBannerR1: action.value }
    case 'VerticalBannerR2': return { ...state, VerticalBannerR2: action.value }
    case 'VerticalBannerR3': return { ...state, VerticalBannerR3: action.value }

    case 'PassCodeActive': return { ...state, PassCodeActive: action.value }
    case 'PassCode': return { ...state, PassCode: action.value }

    case 'WelcomeBanner': return { ...state, WelcomeBanner: action.value }

    case 'MapURL': return { ...state, MapURL: action.value }

    case 'Avatars': return { ...state, Avatars: action.value }
    case 'Venue': return { ...state, Venue: action.value }
    case 'IsPrivate': return { ...state, IsPrivate: action.value }
    case 'Posters': return { ...state, Posters: { ...state.Posters, ...action.value } }

    case 'Rooms': return { ...state, Rooms: action.value, RoomsLimit: action.value.length }

    case 'Custom': {

      return { ...state, [action.data.name]: action.data.value }

    }
    case 'setEvent': return { ...state, ...action.event }
    default: return initialState
  }
}