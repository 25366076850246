import React, { useState } from 'react'
import Header from '../../../baseComponents/FormHeader'
import { Paper, Button, Typography, } from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'

import { generateString } from '../../../utils/misc'
import { useStyles } from '../../../utils/styles'

const CreateGame = props => {
  const { eventId } = props
    , classes = useStyles()
    , [game, setGame] = useState({
      GameId: `${eventId}${generateString(32)}`,
      Logo: "https://hoppia.s3.us-west-2.amazonaws.com/game/z-999989/game.png",
      Name: "Juego Kart",
      Media: {
        Poster1: {
          Available: true,
          FieldName: "",
          Image: "https://hoppia.s3.us-west-2.amazonaws.com/game/z-999989/game.png",
          Link: "",
          Type: "image",
          Video: "",
        },
        Poster2: {
          Available: true,
          FieldName: "",
          Image: "https://hoppia.s3.us-west-2.amazonaws.com/game/z-999989/game.png",
          Link: "",
          Type: "image",
          Video: "",
        },
      }
    })
  const onSubmitEvent = () => {
    props.onCreate(game.Name, game)
  }

  const onTitleChange = title => {
    let gamePayload = { ...game, Name: title }
    setGame(gamePayload)
  }


  /* let inputs = Object.keys(game.Media)
    .map(key => ({ FieldKey: key, ...game.Media[key] }))
    .sort((a, b) => a.index - b.index) */

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header mainTitle={game.Name} isNewEvent={false} id={game.GameId} />
    <Paper className={classes.paper}>
      <div className='field'>
        <TextField fullWidth margin='normal' label='Título' value={game.Name}
          onChange={e => onTitleChange(e.target.value)}
          placeholder='Nombre del juego' />
      </div>
    </Paper>

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Guardar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={props.onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default CreateGame