import React, { useState, useReducer, useEffect } from 'react'
import { Button, Typography } from '@material-ui/core'
import { initialState, reducer } from './formReducer'
import { formValues } from './defaultValues'
import { useStyles } from './styles'
import { fbDatabase } from '../../services/firebase'
import Header from './components/Header'
import BasicInfo from './components/BasicInfo'
import Boutique from './components/Boutique'
import Loading from '../../baseComponents/Loader'

import './index.css'

const IslandForm = ({
  eventId = 'eventId',
  id = '1111',
  isNewIsland = false,
  island = null,
  onSubmit = () => null,
  onCancel
}) => {

  const [state, dispatch] = useReducer(reducer, initialState)
    , [isSaving, setIsSaving] = useState(true)
    , classes = useStyles()
    , [boutiques, setBoutiques] = useState(null)
    , onFormChange = (type, value) => { dispatch({ type, value }) }

  const onChange = list => {
    let boutiques = list.map(item => item.Unassigned ? 'empty' : item.Id)
    dispatch({ type: 'Boutiques', value: boutiques })
  }

  const onBoutiqueAssigned = (index, id) => {
    let boutiques = state.Boutiques
    boutiques[index] = id

    dispatch({ type: 'Boutiques', value: boutiques })
  }

  const onSubmitEvent = async () => {
    setIsSaving(true)

    let payload = {
      ...state,
      Title: state.Title.length ? state.Title : formValues.Title,
      Details: state.Details.length ? state.Details : formValues.Details,
      Available: state.Available,
    }

    if (isNewIsland) {
      payload.Created_At = Date.now()
      payload.Boutiques = (new Array(payload.BoutiquesSize)).fill('empty')
    }

    const islandRef = fbDatabase.ref('ISLANDS').child(id)
    islandRef.set({
      ...island,
      ...payload
    })

    setIsSaving(false)
    dispatch({ type: 'reset' })
    onSubmit(payload)
  }


  useEffect(() => {
    const boutiquesRef = fbDatabase.ref('BOUTIQUES').orderByChild('EventId').equalTo(eventId)

    boutiquesRef.once('value', snapshot => {
      let boutiqueSnapshot = snapshot.val()
      if (boutiqueSnapshot)
        setBoutiques(boutiqueSnapshot)

      setIsSaving(false)
    })

    if (island) {
      dispatch({ type: 'setEvent', event: { ...island } })
    }
    dispatch({ type: 'setEvent', event: { EventId: eventId } })


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [island])

  if (isSaving) return <Loading />

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header isNewIsland={isNewIsland} id={id} />
    <div className={classes.root}>
      <BasicInfo id={id} defaultValues={formValues} isNewIsland={isNewIsland}
        onFormChange={onFormChange} values={state} />
    </div>
    {
      !isNewIsland && Boolean(boutiques) &&
      <Boutique
        onBoutiqueAssigned={onBoutiqueAssigned}
        boutiques={state.Boutiques}
        boutiquesList={boutiques}
        map={state.Map}
        onChange={onChange} />
    }

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        {isNewIsland ? 'Crear' : 'Actualizar'}
      </Typography>
    </Button>

    {
      typeof onCancel === 'function'
        ? <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
          <Typography>Cancelar</Typography>
        </Button>
        : null
    }
  </form>
}

export default IslandForm
