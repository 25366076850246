import React, { useState, useEffect } from 'react'
import { Container } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'

import VenuesBar from './components/VenuesBar'
import VenueItem from './components/VenueItem'
import VenueForm from '../../forms/VenueForm'
import Loader from '../../baseComponents/Loader'

import { useStyles } from './styles'

import './Venues.css'

const Venues = props => {
  const [venues, setVenues] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , [isVenueFormActive, setIsVenueFormActive] = useState(false)
    , classes = useStyles()

  useEffect(() => {
    const venuesRef = fbDatabase.ref('VENUES')
    venuesRef.on('value', snapshot => {
      let venuesnapshot = snapshot.val()
        , venuesList = []
      for (let id in venuesnapshot)
        venuesList.push({ id, ...venuesnapshot[id] })

      venuesList = venuesList.sort((a, b) => a.id - b.id)
      setVenues(venuesList)
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return <Loader />

  if (isVenueFormActive) {
    let lastId = venues.map(evt => {
      let idNumber = evt.id.split('-')[1]
      return Number(idNumber)
    }).sort((a, b) => a - b)[0] || 1000000

    lastId = `venue-${--lastId}`

    return <Container maxWidth='xl'>
      <VenueForm isNewVenue={true} id={lastId}
        onSubmit={() => setIsVenueFormActive(false)}
        onCancel={() => setIsVenueFormActive(false)} />
    </Container>
  }

  return <div id='venues'>
    <VenuesBar onShowForm={() => setIsVenueFormActive(true)} />
    <div className={`venue-list ${classes.list}`}>
      {
        venues.map(venue => <VenueItem key={venue.id} venue={venue} />)
      }
    </div>
  </div>
}

export default Venues