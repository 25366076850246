import React, { useEffect, useState, useContext } from 'react'
import {
  Container, Paper, Typography,
} from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { getUrl } from '../../utils/routes'
import UsersTable from './components/Table'
import Loader from '../../baseComponents/Loader'

import { useStyles } from './styles'
import { EventInfoContext } from '../../providers/EventInfoProvider'

const Users = props => {
  const { eventId } = props.match.params
  const context = useContext(EventInfoContext)
    , { state } = context

  const [users, setUsers] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , classes = useStyles()

  const onSetUser = (user) => {
    props.history.push(`${getUrl('event')}/${eventId}${getUrl('user')}/${user.Id}`)
  }

  useEffect(() => {
    const checkInRef = fbDatabase.ref('EVENT_CHECK_IN').child(eventId)
    checkInRef.on('value', snapshot => {
      let checkInSnapshot = snapshot.val()
        , checkInList = []

      if (checkInSnapshot !== null)
        for (let Id in checkInSnapshot)
          checkInList.push({ Id, ...checkInSnapshot[Id] })

      setUsers(checkInList)
      setIsFetching(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetching) return <Loader />

  let amountOfSessions = 0

  if (users.length) amountOfSessions = users
    .map(user => user.checkIn)
    .reduce((a, b) => a + b)

  return <div id='users'>
    <Container maxWidth='xl' className={classes.container}>
      <Paper className={classes.paper}>
        <Typography>Accessos totales a {state.Title}: {amountOfSessions}</Typography>
      </Paper>
      <UsersTable rows={users} onSetUser={onSetUser} />
    </Container>
  </div>
}

export default Users