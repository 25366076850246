import React, { useContext } from 'react'
import { useStyles } from '../../utils/styles'

import Header from '../../baseComponents/FormHeader'
import { Paper, Button, Typography } from '@material-ui/core'
import { EventInfoContext } from '../../providers/EventInfoProvider'
import Room from './components/Room'

const Videos = props => {
  const context = useContext(EventInfoContext)
    , { state, onFormChange, onSave, onCancel, } = context
    , classes = useStyles()
  const { eventId } = props.match.params

  const onSubmitEvent = (e) => {
    e.preventDefault()
    onSave(() => { })
  }

  const onChange = (item, position) => {
    const items = state.Rooms.map((room, index) => position === index ? item : room)
    onFormChange('Rooms', items)
  }

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header mainTitle='Videos' isNewEvent={false} id={eventId} />

    {
      state.Rooms.map((room, index) => <Paper key={index} className={`${classes.paper} ${classes.roomCard}`}>
        <Room key={index} index={index} room={room} onChange={onChange} />
      </Paper>)
    }

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Actualizar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default Videos