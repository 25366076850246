export const initialState = {
  Title: '',
  Available: false,
  Details: '',
  EventId: '',
  IsMainIsland: false,
  BoutiquesSize: 10,
  Map: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
  Boutiques: []
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'Title': return { ...state, Title: action.value }
    case 'Details': return { ...state, Details: action.value }
    case 'Available': return { ...state, Available: action.value }
    case 'Map': return { ...state, Map: action.value }
    case 'Boutiques': return { ...state, Boutiques: action.value }
    case 'setEvent': return { ...state, ...action.event }
    default: return initialState
  }
}