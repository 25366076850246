import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4)
  },
  avatar: {
    height: theme.spacing(8),
    top: -theme.spacing(4),
    width: theme.spacing(8),
  },
  error: {
    color: theme.palette.error.light,
    marginTop: theme.spacing(3)
  }
}))