import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  success: {
    background: theme.palette.success.light,
    color: 'white',
  },
  error: {
    color: theme.palette.error.light,
    marginTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(3),
    flexDirection: 'column',
  },
  formBtn: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(1)
  },
}))