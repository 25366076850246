import React, { useEffect, useState, createContext } from 'react'
import { fbAuth, fbDatabase } from '../services/firebase'

import Loader from '../baseComponents/Loader'
import Login from '../screens/Login'

export const AuthContext = createContext({ user: null })

const AuthProvider = props => {
  const [user, setUser] = useState(null)
    , [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fbAuth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        let userRef = fbDatabase.ref('CMS_USERS').child(currentUser.uid)
        userRef.once('value', snapshot => {
          let user = snapshot.val()
          if (!user) {
            setUser(null)
            fbAuth.signOut()
          } else {
            setUser({ email: currentUser.email, ...user, meta: currentUser })
          }
          setIsLoading(false)
        })
      } else {
        setUser(null)
        setIsLoading(false)
      }
    })
  }, [])


  if (isLoading) return <Loader />

  return !user
    ? <Login />
    : <AuthContext.Provider value={{ user, updateState: setUser }}>
      {props.children}
    </AuthContext.Provider>
}

export default AuthProvider