import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import axios from 'axios'

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATA_BASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
})

export const fbDatabase = firebase.database()
export const fbAuth = firebase.auth()
export const authWithEmailAndPassword = (email, password, cb) => {
  //eslint-disable-next-line
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailRegex.test(email)) return cb('* El correo electrónico no cumple conn el formato', null)
  if (password.length < 6) return cb('* La contraseña debe contener al menos 6 caracteres')

  fbAuth.signInWithEmailAndPassword(email, password)
    .then(result => {
      let userRef = fbDatabase.ref('CMS_USERS').child(result.user.uid)
      userRef.once('value', snapshot => {
        let user = snapshot.val()

        if (!user) return cb(errorList['auth/not-valid-user'], null)

        return cb(null, { email: result.user.email, ...user, })
      })
    })
    .catch(err => {
      let error = errorList[err.code]
        ? errorList[err.code]
        : 'Error desconocido'
      cb(error, null)
    })
}
export const createUser = (email, password, username, cb) => {
  //eslint-disable-next-line
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!emailRegex.test(email)) return cb('* El correo electrónico no cumple conn el formato', null)
  if (password.length < 6) return cb('* La contraseña debe contener al menos 6 caracteres', null)
  if (username.length < 3) return cb('* El nombre de usuario debe tener al menos 3 caracteres', null)

  let data = `email=${email}&password=${password}&username=${username}`

  return axios({
    method: 'POST',
    url: 'https://hoppia.herokuapp.com/create-user',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
    .then(res => {
      if (res.data.message) return cb(res.data.message, null)
      return cb(null, res.data)
    })
    .catch(err => console.log(err))
}

let response = {
  users: [],
  token: ':D'
}

export const getUserList = (token) => {

  if (response.users.length) return new Promise((resolve, reject) => {
    resolve(response)
  })

  return axios({
    method: 'POST',
    url: 'https://us-central1-hoppiaemail.cloudfunctions.net/getUserList',
    data: 'accessToken=243be4feca2f29b0df293f076b7a7499',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
    .then(res => {
      response = res.data
      return res.data
    })
}


const errorList = {
  'auth/user-not-found': '* Usuario o contraseña incorrectos',
  'auth/wrong-password': '* Usuario o contraseña incorrectos',
  'auth/not-valid-user': '* Este usuario no tiene permisos para acceder',
  'auth/email-already-in-use': '* El correo electrónico ya esta registrado',
  'auth/email-already-exists': '* El correo electrónico ya esta registrado'
}
