import React from 'react'
import { InputLabel, MenuItem, Select, Switch, FormControl, Paper } from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'
import ImageField from './ImageField'
import ImageSelector from './ImageSelector'
import { useStyles } from '../styles'

const BasicInfo = ({
  id,
  values,
  defaultValues,
  onFormChange,
}) => {
  const classes = useStyles()
  return <Paper className={classes.form}>
    <div className='field'>
      <TextField fullWidth margin='normal' label='Título' value={values.Title}
        onChange={e => onFormChange('Title', e.target.value)}
        placeholder={defaultValues.Title} />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Id ecommerce' value={values.IdEcommerce}
        onChange={e => onFormChange('IdEcommerce', e.target.value)}
        placeholder={defaultValues.IdEcommerce} />
    </div>
    <div className='field field-margin-top'>
      <InputLabel className='label-margin'>Boutique disponible</InputLabel>
      <div style={{ alignItems: 'baseline', display: 'flex' }}>
        <InputLabel>No</InputLabel>
        <Switch checked={values.Available} onChange={() => onFormChange('Available', !values.Available)} />
        <InputLabel>Sí</InputLabel>
      </div>
    </div>

    <div className='field'>
      <FormControl className={classes.formControl}>
        <InputLabel>Tamaño</InputLabel>
        <Select
          value={values.Size}
          onChange={(e) => onFormChange('Size', e.target.value)}
          defaultValue={defaultValues.Size}
        >
          <MenuItem value='S'>Pequeño</MenuItem>
          <MenuItem value='M'>Mediano</MenuItem>
          <MenuItem value='B'>Grande</MenuItem>
        </Select>
      </FormControl>
    </div>

    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <ImageField
        id={id}
        label='Logotipo Menú'
        onChange={url => onFormChange('Logo', url)}
        placeholder={defaultValues.Logo}
        value={values.Logo}
      />
    </div>
    <div>
      <ImageSelector
        id={id}
        label='Marquesina'
        onChange={url => onFormChange('Roof', url)}
        placeholder={defaultValues.Roof}
        value={values.Roof}
      />
      {
        values.Size === 'S' &&
        <React.Fragment>
          <ImageSelector
            id={id}
            label='Poster'
            onChange={url => onFormChange('MainPoster', url)}
            placeholder={defaultValues.MainPoster}
            value={values.MainPoster}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 1'
            onChange={url => onFormChange('SmallSideboard1', url)}
            placeholder={defaultValues.SmallSideboard1}
            value={values.SmallSideboard1}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 2'
            onChange={url => onFormChange('SmallSideboard2', url)}
            placeholder={defaultValues.SmallSideboard2}
            value={values.SmallSideboard2}
          />
          <ImageSelector
            id={id}
            label='Aparador grande'
            onChange={url => onFormChange('BigSideboard1', url)}
            placeholder={defaultValues.BigSideboard1}
            value={values.BigSideboard1}
          />
        </React.Fragment>
      }
      {
        values.Size !== 'S' &&
        <React.Fragment>
          <ImageSelector
            id={id}
            label='Poster izquierdo'
            onChange={url => onFormChange('LeftPoster', url)}
            placeholder={defaultValues.LeftPoster}
            value={values.LeftPoster}
          />
          <ImageSelector
            id={id}
            label='Poster central'
            onChange={url => onFormChange('MainPoster', url)}
            placeholder={defaultValues.MainPoster}
            value={values.MainPoster}
          />
          <ImageSelector
            id={id}
            label='Poster derecho'
            onChange={url => onFormChange('RightPoster', url)}
            placeholder={defaultValues.RightPoster}
            value={values.RightPoster}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 1'
            onChange={url => onFormChange('SmallSideboard1', url)}
            placeholder={defaultValues.SmallSideboard1}
            value={values.SmallSideboard1}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 2'
            onChange={url => onFormChange('SmallSideboard2', url)}
            placeholder={defaultValues.SmallSideboard2}
            value={values.SmallSideboard2}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 3'
            onChange={url => onFormChange('SmallSideboard3', url)}
            placeholder={defaultValues.SmallSideboard3}
            value={values.SmallSideboard3}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 4'
            onChange={url => onFormChange('SmallSideboard4', url)}
            placeholder={defaultValues.SmallSideboard4}
            value={values.SmallSideboard4}
          />
        </React.Fragment>
      }
      {
        values.Size === 'B' &&
        <React.Fragment>
          <ImageSelector
            id={id}
            label='Aparador chico 5'
            onChange={url => onFormChange('SmallSideboard5', url)}
            placeholder={defaultValues.SmallSideboard5}
            value={values.SmallSideboard5}
          />
          <ImageSelector
            id={id}
            label='Aparador chico 6'
            onChange={url => onFormChange('SmallSideboard6', url)}
            placeholder={defaultValues.SmallSideboard6}
            value={values.SmallSideboard6}
          />
          <ImageSelector
            id={id}
            label='Aparador grande 1'
            onChange={url => onFormChange('BigSideboard1', url)}
            placeholder={defaultValues.BigSideboard1}
            value={values.BigSideboard1}
          />
          <ImageSelector
            id={id}
            label='Aparador grande 1'
            onChange={url => onFormChange('BigSideboard2', url)}
            placeholder={defaultValues.BigSideboard2}
            value={values.BigSideboard2}
          />
        </React.Fragment>
      }
    </div>
  </Paper>
}

export default BasicInfo


