import React from 'react'
import { Fab, Paper, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { getUrl } from '../../../utils/routes'
import { useStyles } from '../styles'

const AvatarItem = ({ avatar, history }) => {
  const clases = useStyles()
  const onClickHandler = () => {
    history.push(`${getUrl('avatar')}/${avatar.id}`)
  }

  return <div className='avatar-item'>
    <Paper className='avatar-paper'>
      <div className={`avatar-container ${clases.containerMargin}`}>
        <img className={`avatar-picture ${clases.picture}`} src={avatar.Picture} alt={avatar.Name} />
        <Typography className='event-title' component='h2' variant='h4'>{avatar.Name}</Typography>
        <Fab className='btn-avatar' color='default' size='medium' onClick={onClickHandler}>
          <ChevronRight />
        </Fab>
      </div>
    </Paper>
  </div>
}

export default withRouter(AvatarItem)