import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  filterSection: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}))