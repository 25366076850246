import React, { useState, useEffect } from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import Table from './Table'
import { fbDatabase } from '../../../services/firebase'
import { useStyles } from '../../../utils/styles'

const Ranking = props => {
  const { id, onGoBack, title } = props
    , [ranking, setRanking] = useState([])
    , classes = useStyles()
  useEffect(() => {
    const rankingRef = fbDatabase.ref('GAME_RANKING').child(id)

    rankingRef.on('value', snapshot => {
      let rankSnapshot = snapshot.val()
        , rankList = []
      if (!rankSnapshot) return
      for (let Id in rankSnapshot)
        rankList.push({ Id, ...rankSnapshot[Id] })

      rankList = rankList.sort((a, b) => Number(a.Points) - Number(b.Points)).reverse()
      setRanking(rankList)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div>
    <Typography className={classes.paper} variant='h4'>{title}</Typography>
    <Paper className={`${classes.paper} ${classes.roomCard}`}>
      <Button variant='outlined' onClick={onGoBack} >Regresar</Button>
    </Paper>
    <Paper className={classes.paper}>
      <Table rows={ranking} />
    </Paper>
  </div>
}

export default Ranking