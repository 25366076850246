import React from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { useStyles } from '../styles'


const EventBar = ({
  onSubmit,
}) => {
  const classes = useStyles()

  return <Paper className={classes.paper}>
    <Button onClick={onSubmit} className={classes.addEventBtn} variant='outlined'>
      <Typography>Guardar cambios</Typography>
    </Button>
  </Paper>
}

export default EventBar