import React from 'react'
import { Fab, Paper, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { withRouter } from 'react-router-dom'
import { getUrl } from '../../../utils/routes'

const VenueItem = ({ venue, history }) => {
  const onClickHandler = () => {
    history.push(`${getUrl('venue')}/${venue.id}`)
  }

  return <div className='venue-item'>
    <Paper className='venue-paper' style={{ background: `url('${venue.Picture}')` }}>
      <div className='venue-container'>
        <Typography className='venue-title' component='h2' variant='h4'>{venue.Name}</Typography>
        <Fab className='btn-venue' color='default' size='medium' onClick={onClickHandler}>
          <ChevronRight />
        </Fab>
      </div>
    </Paper>
  </div>
}

export default withRouter(VenueItem)