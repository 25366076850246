import React from 'react'
import Title from './Title'
import { Paper } from '@material-ui/core'
import { useStyles } from '../styles'

const ActiveEvents = props => {
  const classes = useStyles()
  const { events } = props
    , activeEvents = events.filter(event => event.IsReady)
    , inactiveEvents = events.filter(event => !event.IsReady)

  return <Paper className={classes.paper}>
    <Title>{activeEvents.length} evento{activeEvents.length > 1 ? 's' : ''} en producción</Title>
    <Title>{inactiveEvents.length} evento{inactiveEvents.length > 1 ? 's' : ''} en desarrollo</Title>
  </Paper>
}

export default ActiveEvents