import React, { useState, useEffect } from 'react'
import {
  Paper, Button, DialogContentText,
  Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { generateString } from '../../utils/misc'
import { useStyles } from './styles'

import Loader from '../../baseComponents/Loader'
import EventBar from './components/EventBar'
import EventForm from '../../forms/AgendaForm'
import EventsTable from './components/Table'
import './Events.css'


const Agenda = props => {
  const [events, setEvents] = useState([])
    , [event, setEvent] = useState(null)
    , [isFetching, setIsFetching] = useState(true)
    , [isEventFormActive, setIsEventFormActive] = useState(false)
    , [itemId, setitemId] = useState('')
    , [eventTitle, setEventTitle] = useState('')
    , classes = useStyles()

  const { eventId } = props.match.params

  const onReset = () => {
    setEvent(null)
    setIsEventFormActive(false)
  }

  const onEdit = event => {
    setEvent(event)
    setIsEventFormActive(true)
  }

  const onDelete = () => {
    const eventRef = fbDatabase.ref('HOPPIA_AGENDA').child(itemId)
    eventRef.remove()
    setitemId('')
    setEventTitle('')
  }

  const onRequestDeletion = (id, title) => {
    setEventTitle(title)
    setitemId(id)
  }

  useEffect(() => {
    const eventsRef = fbDatabase.ref('HOPPIA_AGENDA').orderByChild('EventId').equalTo(eventId)
    eventsRef.on('value', snapshot => {
      let eventSnapshot = snapshot.val()
        , eventList = []

      for (let id in eventSnapshot)
        eventList.push({ id, ...eventSnapshot[id] })

      // eventList = eventList.sort((a, b) => a.id - b.id)
      setEvents(eventList)
      setIsFetching(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetching) return <Loader />

  if (isEventFormActive) {
    let lastId = Boolean(event) ? event.id : `${generateString(8)}${Date.now()}`
    return <EventForm
      eventId={eventId}
      isNewEvent={!event}
      event={event}
      id={lastId}
      onSubmit={onReset}
      onCancel={onReset} />
  }

  return <div id='events'>
    <EventBar onShowForm={() => setIsEventFormActive(true)} />
    <Paper className={classes.paper}>
      <EventsTable rows={events} onEdit={onEdit} onDelete={onRequestDeletion} />
    </Paper>
    <Dialog
      open={Boolean(itemId.length)}
      onClose={() => setitemId('')}
    >
      <DialogTitle >¿Desea eliminar {eventTitle}?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Advertencia: al dar click sobre eliminar, todos los datos se eliminan inmediatamente. Esta acción es irreversible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={`${classes.btn} ${classes.btnCancel}`} onClick={() => setitemId('')} variant='contained'>
          Cancelar
        </Button>
        <Button className={`${classes.btn} ${classes.btnDelete}`} onClick={onDelete} variant='contained' autoFocus>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  </div>
}

export default Agenda
