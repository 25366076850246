import React from 'react'
import { LinearProgress, Typography } from '@material-ui/core'

const Loader = props => {
  return <div className='loader'>
    <LinearProgress />
    <Typography variant='h4'>{props.text ? props.text : 'Cargando...'}</Typography>
    <LinearProgress color='secondary' />
  </div>
}

export default Loader