import React, { useContext } from 'react'
import { useStyles } from '../../utils/styles'

import Header from '../../baseComponents/FormHeader'
import { Paper, Button, Typography, InputLabel, Switch, Select, MenuItem, Slider } from '@material-ui/core'
import { EventInfoContext } from '../../providers/EventInfoProvider'
import { generateRandomPassCode } from '../../utils/misc'
import ImageField from '../../baseComponents/ImageField'
import TextField from '../../baseComponents/TextField'


const Home = props => {
  let { eventId } = props.match.params

  const context = useContext(EventInfoContext)
    , { state, onFormChange, onSave, onCancel, formValues } = context
    , classes = useStyles()

  const onSubmitEvent = (e) => {
    e.preventDefault()
    onSave(() => { })
  }

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Header mainTitle='Información general' isNewEvent={false} id={eventId} />
    <Paper className={classes.paper}>
      <div className='field'>
        <TextField fullWidth margin='normal' label='Título' value={state.Title}
          onChange={e => onFormChange('Title', e.target.value)}
          placeholder={formValues.Title} />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='Detalles' value={state.Details}
          onChange={e => onFormChange('Details', e.target.value)}
          placeholder={formValues.Details}
        />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='Fecha de inicio'
          type='datetime-local' InputLabelProps={{ shrink: true, }} value={state.StartDate}
          onChange={e => onFormChange('StartDate', e.target.value)} placeholder={formValues.StartDate}
        />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='Fecha de fin'
          type='datetime-local' InputLabelProps={{ shrink: true, }} value={state.EndDate}
          onChange={e => onFormChange('EndDate', e.target.value)}
          placeholder={formValues.EndDate}
        />
      </div>

      <div className='field'>
        <Typography id="non-linear-slider" gutterBottom>
          Número de usuarios por cuarto
        </Typography>
        <Slider
          min={2}
          max={100}
          className={classes.slider}
          onChange={(_, value) => onFormChange('MaxPlayersPerRoom', value)}
          value={state.MaxPlayersPerRoom}
          valueLabelDisplay='on'
        />
      </div>

      <div className='field field-margin-top' style={{ marginBottom: '1rem' }}>
        <InputLabel id='availability' className='label-margin'>Seleccione la disponibilidad</InputLabel>
        <Select fullWidth labelId='availability' id='select' value={state.Availability} onChange={e => onFormChange('Availability', e.target.value)}>
          <MenuItem value={true}>Disponible</MenuItem>
          <MenuItem value={false}>No disponible</MenuItem>
        </Select>
      </div>

      <div className='field field-margin-top' style={{ marginBottom: '1rem' }}>
        <InputLabel id='IsPrivate' className='label-margin'>Evento privado</InputLabel>
        <Select fullWidth labelId='IsPrivate' id='select' value={state.IsPrivate} onChange={e => onFormChange('IsPrivate', e.target.value)}>
          <MenuItem value={true}>Sí</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      </div>

      <div className='field field-margin-top'>
        <InputLabel className='label-margin'>Evento privado</InputLabel>
        <div style={{ alignItems: 'baseline', display: 'flex' }}>
          <InputLabel>No</InputLabel>
          <Switch checked={state.IsPrivate} onChange={() => onFormChange('IsPrivate', !state.IsPrivate)} />
          <InputLabel>Sí</InputLabel>
        </div>
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='URL del auditorio'
          value={state.StreamURL} onChange={e => onFormChange('StreamURL', e.target.value)}
          placeholder={formValues.StreamURL} />
      </div>

      <div className='field'>
        <TextField fullWidth margin='normal' label='URL de la sala VIP'
          value={state.StreamURL2} onChange={e => onFormChange('StreamURL2', e.target.value)}
          placeholder={formValues.StreamURL2} />
      </div>

      <ImageField
        id={eventId}
        label='URL del póster vertical'
        onChange={url => onFormChange('PosterURL', url)}
        placeholder={formValues.PosterURL}
        value={state.PosterURL}
      />

      <div className='field'>
        <ImageField
          id={eventId}
          label='URL del póster horizontal'
          onChange={url => onFormChange('AltPosterURL', url)}
          placeholder={formValues.PasilloDeLaFama_PosterURL}
          value={state['AltPosterURL'] ? state['AltPosterURL'] : state.PasilloDeLaFama_PosterURL}
        />
      </div>
    </Paper>

    <div className='field field-margin-top'>
      <InputLabel className='label-margin'>PassCode</InputLabel>
      <div style={{ alignItems: 'baseline', display: 'flex' }}>
        <InputLabel>No</InputLabel>
        <Switch checked={state.PassCodeActive} onChange={() => {
          if (state.PassCodeActive) onFormChange('PassCode', '')
          onFormChange('PassCodeActive', !state.PassCodeActive)
        }} />
        <InputLabel>Sí</InputLabel>
      </div>
    </div>

    {
      state.PassCodeActive
        ? <div className='field flex-row'>
          <TextField fullWidth margin='normal' label='PassCode' value={state.PassCode}
            onChange={e => onFormChange('PassCode', e.target.value)}
            placeholder={formValues.PassCode}
          />
          <Button
            className={classes.btn}
            variant='outlined'
            onClick={() => onFormChange('PassCode', generateRandomPassCode(8))}>
            <Typography>Generar</Typography>
          </Button>
        </div>
        : null
    }

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Actualizar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default Home