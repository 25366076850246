import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  addEventBtn: {
    color: theme.palette.success.main,
  },
  list: {
    padding: theme.spacing(2)
  },
  containerMargin: {
    padding: theme.spacing(4)
  },
  picture: {
    marginBottom: theme.spacing(4)
  }
}))