import React, { useState, useEffect } from 'react'
import { Container } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'

import AvatarBar from './components/AvatarBar'
import AvatarItem from './components/AvatarItem'
import AvatarForm from '../../forms/AvatarForm'
import Loader from '../../baseComponents/Loader'

import { useStyles } from './styles'

import './Avatars.css'

const Avatars = props => {
  const [avatars, setAvatars] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , [isAvatarFormActive, setIsAvatarFormActive] = useState(false)
    , classes = useStyles()

  useEffect(() => {
    const avatarsRef = fbDatabase.ref('AVATARS')
    avatarsRef.on('value', snapshot => {
      let avatarsnapshot = snapshot.val()
        , avatarList = []
      for (let id in avatarsnapshot)
        avatarList.push({ id, ...avatarsnapshot[id] })

      avatarList = avatarList.sort((a, b) => a.id - b.id).reverse()
      setAvatars(avatarList)
      setIsFetching(false)
    })
  }, [])

  if (isFetching) return <Loader />

  if (isAvatarFormActive) {
    let lastId = avatars.map(evt => {
      let idNumber = evt.id.split('-')[1]
      return Number(idNumber)
    }).sort((a, b) => a - b)[0] || 1000000

    lastId = `avatar-${--lastId}`

    return <Container maxWidth='xl'>
      <AvatarForm isNewAvatar={true} id={lastId}
        onSubmit={() => setIsAvatarFormActive(false)}
        onCancel={() => setIsAvatarFormActive(false)} />
    </Container>
  }

  return <div id='avatars'>
    <AvatarBar onShowForm={() => setIsAvatarFormActive(true)} />
    <div className={`avatar-list ${classes.list}`}>
      {
        avatars.map(avatar => <AvatarItem key={avatar.id} avatar={avatar} />)
      }
    </div>
  </div>
}

export default Avatars