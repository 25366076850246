import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  backBtn: {
    marginRight: theme.spacing(2)
  },
  dialog: {
    display: 'flex',
    flexDirection: 'row'
  }
}))