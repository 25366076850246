import React from 'react'
import { Paper, Switch, InputLabel } from '@material-ui/core'
import ImageField from '../../../baseComponents/ImageField'
import TextField from '../../../baseComponents/TextField'
import { useStyles } from '../styles'

const BasicInfo = ({
  id,
  isNewEvent = true,
  values,
  defaultValues,
  onFormChange,
}) => {
  const classes = useStyles()
  return <Paper className={classes.form}>
    <div className='field'>
      <TextField fullWidth margin='normal' label='Título' value={values.Title}
        onChange={e => onFormChange('Title', e.target.value)}
        placeholder={defaultValues.Title} />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Texto' value={values.Text}
        onChange={e => onFormChange('Text', e.target.value)}
        placeholder={defaultValues.Text}
      />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='URL'
        value={values.URL} onChange={e => onFormChange('URL', e.target.value)}
        placeholder={defaultValues.URL} />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Fecha'
        type='datetime-local' InputLabelProps={{ shrink: true, }} value={values.Date}
        onChange={e => onFormChange('Date', e.target.value)}
      />
    </div>

    <ImageField
      id={id}
      folder='news'
      label='Póster del evento'
      onChange={url => onFormChange('PosterURL', url)}
      placeholder={defaultValues.PosterURL}
      value={values.PosterURL}
    />

    <div className='field field-margin-top'>
      <InputLabel className='label-margin'>Mostrar en la app</InputLabel>
      <div style={{ alignItems: 'baseline', display: 'flex' }}>
        <InputLabel>No</InputLabel>
        <Switch checked={values.Show} onChange={() => onFormChange('Show', !values.Show)} />
        <InputLabel>Sí</InputLabel>
      </div>
    </div>
  </Paper>
}

export default BasicInfo