import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import ImageField from './ImageField'
import { useStyles } from '../styles'

const ImageSelector = ({
  id,
  label,
  onChange,
  placeholder,
  value
}) => {
  const classes = useStyles()
    , [SDImage, setSDImage] = useState('')
    , [HDImage, setHDImage] = useState('')

  const onSDChange = url => {
    setSDImage(url)
    onChange({
      SD: url,
      HD: HDImage
    })
  }
  const onHDChange = url => {
    setHDImage(url)
    onChange({
      HD: url,
      SD: SDImage
    })
  }

  useEffect(() => {
    setSDImage(value.SD)
    setHDImage(value.HD)
  }, [value])

  return <div className='image__selector'>
    <Typography className={classes.imageFieldTitle} component='h5' variant='h5' >{label}</Typography>
    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
      <ImageField
        label='SD'
        id={id}
        onChange={onSDChange}
        placeholder={SDImage}
        value={SDImage}
      />
      <ImageField
        label='HD'
        id={id}
        onChange={onHDChange}
        placeholder={HDImage}
        value={HDImage}
      />

    </div>
  </div>
}

export default ImageSelector