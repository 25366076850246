import React, { useState, useEffect } from 'react'
import {
  Container, Typography,
} from '@material-ui/core'
import { useStyles } from './styles'

import Loading from '../../baseComponents/Loader'
import AvatarForm from '../../forms/AvatarForm'

import { fbDatabase } from '../../services/firebase'

import './Avatar.css'

const Avatar = props => {
  const [avatar, setAvatar] = useState(null)
    , [isFetching, setIsFetching] = useState(true)
    , classes = useStyles()

  useEffect(() => {
    let { id } = props.match.params
    const avatarRef = fbDatabase.ref('AVATARS').child(id)
    avatarRef.on('value', snapshot => {
      setIsFetching(true)
      let avatar = snapshot.val()

      if (!avatar) {
        setIsFetching(false)
        return setAvatar(avatar)
      }
      setAvatar(avatar)
      setIsFetching(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetching) return <Loading />
  if (!avatar) return <Container maxWidth='xl' className={classes.container}>
    <Typography component='h2' variant='h5'>
      Avatar no encontrado
    </Typography>
  </Container>

  return <Container maxWidth='xl' className={classes.container}>
    <AvatarForm isNewAvatar={false}
      id={props.match.params.id}
      avatar={avatar}
    />
  </Container>
}

export default Avatar