import React, { useState, useContext, useEffect } from 'react'
import Header from '../../baseComponents/FormHeader'
import { Paper, Button, Typography, } from '@material-ui/core'
import ImageField from '../../baseComponents/ImageField'
import Billboard from './components/BillBoard'
import Ranking from './components/Ranking'
import GlobalRanking from './components/GlobalRanking'
import CreateGame from './components/CreateGame'

import { EventInfoContext } from '../../providers/EventInfoProvider'
import { useStyles } from '../../utils/styles'

const Games = props => {
  const context = useContext(EventInfoContext)
    , { mediaGames, onMediaGameFormChange, onSave, onCancel } = context
    , [createGame, setCreateGame] = useState(false)
    , [game, setGame] = useState(null)
    , [gameTitle, setGameTitle] = useState('')
    , [rankingVisible, setRankingVisible] = useState(false)
    , [globlaRanking, setGlobalRanking] = useState(false)
    , classes = useStyles()
  const { eventId } = props.match.params

  const onSubmitEvent = (e) => {
    e.preventDefault()
    onSave(() => { })
  }
  const onImageChange = url => {
    let gamePayload = { ...game, Logo: url }
    setGame(gamePayload)
    onMediaGameFormChange({ ...mediaGames, [gameTitle]: gamePayload })
  }
  const onFormFieldChange = value => {
    let gamePayload = {
      ...game,
      Media: { ...game.Media, ...value }
    }
    setGame(gamePayload)
    onMediaGameFormChange({ ...mediaGames, [gameTitle]: gamePayload })
  }
  const onCreate = (title, game) => {
    setCreateGame(false)
    setGame(game)
    setGameTitle(title)
    onMediaGameFormChange({ ...mediaGames, [title]: game })
  }

  let titles = Object.keys(mediaGames)

  useEffect(() => {
    if (titles.length) {
      let title = gameTitle.length ? gameTitle : titles[0]
      setGame(mediaGames[title])
      setGameTitle(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaGames])

  if (createGame) return <CreateGame
    eventId={eventId}
    onCreate={onCreate}
    onCancel={() => setCreateGame(false)}
  />

  if (!game) return <Paper className={classes.paper}>
    <Button
      className={classes.btn}
      variant='outlined'
      onClick={() => { setCreateGame(true) }}>Crear juego</Button>
  </Paper>

  if (globlaRanking) return <GlobalRanking
    eventId={eventId}
    title='Ranking Global'
    onGoBack={() => setGlobalRanking(false)}
  />

  if (rankingVisible) return <Ranking
    id={game.TempGameId ? game.TempGameId : game.GameId}
    title={game.Name}
    onGoBack={() => setRankingVisible(false)}
  />

  let inputs = Object.keys(game.Media)
    .map(key => ({ FieldKey: key, ...game.Media[key] }))
    .sort((a, b) => a.index - b.index)

  return <form onSubmit={onSubmitEvent} id='event-form'>
    <Paper className={classes.paper}>
      <Button
        className={classes.btn}
        variant='outlined'
        onClick={() => { setCreateGame(true) }}>Crear juego</Button>
      {
        titles.map(title => {
          let gameName = mediaGames[title].Name
          return <Button
            className={classes.btn}
            key={title}
            variant='outlined'
            onClick={() => {
              setGame(mediaGames[title])
              setGameTitle(title)
            }}>{gameName}</Button>
        })
      }
      <Button
        className={classes.btn}
        key='Ranking Global'
        variant='outlined'
        onClick={() => {
          setGlobalRanking(true)
        }}>Ranking Global</Button>
    </Paper>
    <Header mainTitle={game.Name} isNewEvent={false} id={game.GameId} />

    <Button variant='outlined' className={classes.btn} onClick={() => setRankingVisible(true)}>
      Ver Ranking
    </Button>

    <Paper className={classes.paper}>
      <ImageField
        id={game.GameId}
        folder='game'
        label='Póster del juego'
        onChange={onImageChange}
        placeholder={game.Logo}
        value={game.Logo}
      />
      {
        inputs.map((value, index) => {
          return <Billboard
            key={index}
            id={eventId}
            GameFieldName={value.FieldName}
            GameType={value.Type}
            GameValueImage={value.Image}
            GameValueVideo={value.Video}
            GameValueLink={value.Link}
            GameAvailable={value.Available}
            onChange={onFormFieldChange}
            FieldKey={value.FieldKey}
          />
        })
      }
    </Paper>

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitEvent} variant='contained' color='primary'>
      <Typography>
        Guardar
      </Typography>
    </Button>

    <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
      <Typography>Cancelar</Typography>
    </Button>
  </form>
}

export default Games