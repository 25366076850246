import React from 'react'
import { Paper } from '@material-ui/core'
import SortBoutiques from '../../SortBoutiques'
import { useStyles } from '../styles'


const mock = {
  Title: 'Disponible',
  Size: 'S',
  Unassigned: true,
}

const createMock = () => {
  return { ...mock, Id: `${Math.random()}` }
}

const Boutique = ({
  boutiques = [],
  boutiquesList = {},
  map = '',
  onBoutiqueAssigned = () => null,
  onChange
}) => {
  const classes = useStyles()
  let items = boutiques.map((Id, index) => {
    let boutiqueInfo = boutiquesList[Id]
      ? { ...boutiquesList[Id], Id }
      : createMock()
    boutiqueInfo.Index = index + 1
    return boutiqueInfo
  })

  const list = Object.keys(boutiquesList)
    .map(Id => ({ Id, ...boutiquesList[Id] }))

  return <Paper className={`${classes.form} ${classes.table}`}>
    <SortBoutiques
      onBoutiqueAssigned={onBoutiqueAssigned}
      boutiquesList={list}
      map={map}
      rows={items}
      onSave={onChange}
      onCancel={() => null}
    />
  </Paper>
}

export default Boutique