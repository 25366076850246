import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'


const CustomTextField = props => {
  const darkTheme = createTheme({
    palette: {
      type: 'dark',
      primary: { light: '#f5f5f5', main: '#f5f5f5', dark: '#f5f5f5', },
      secondary: { light: '#f5f5f5', main: '#f5f5f5', dark: '#f5f5f5', }
    }
  })

  return <ThemeProvider theme={darkTheme}>
    <TextField {...props} />
  </ThemeProvider>
}

export default CustomTextField