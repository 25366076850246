export const initialState = {
  Name: '',
  Description: '',
  Picture: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
  VenueIndex: '',
  Index: 0,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'Name': return { ...state, Name: action.value }
    case 'Description': return { ...state, Description: action.value }
    case 'Picture': return { ...state, Picture: action.value }
    case 'VenueIndex': return { ...state, VenueIndex: action.value }
    case 'Index': return { ...state, Index: action.value }
    case 'setVenue': return { ...action.venue }
    default: return initialState
  }
}