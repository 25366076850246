import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  devBanner: {
    background: theme.palette.error.dark,
    marginBottom: theme.spacing(4),
  },
  prodBanner: {
    background: theme.palette.success.dark,
  },
  container: {
    paddingBottom: theme.spacing(6)
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  idTag: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(2),
  },
  basicContent: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    position: 'relative',
  },
  status: {
    left: theme.spacing(2),
    padding: theme.spacing(1.5),
    position: 'absolute',
    top: -theme.spacing(3),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  editEvent: {
    color: theme.palette.success.main,
  },
  settingsHeader: {
    background: theme.palette.warning.main,
  },
  settingsContent: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(5),
    position: 'relative',
  },
  deleteEvent: {
    color: theme.palette.warning.main,
  },
  tableContainer: {
    marginTop: theme.spacing(5)
  },
  bannersHeader: {
    background: 'rebeccapurple'
  }
}))