import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import ImageField from '../../../baseComponents/ImageField'
import TextField from '../../../baseComponents/TextField'
import { useStyles } from '../styles'

const BasicInfo = ({
  id,
  values,
  defaultValues,
  onFormChange,
}) => {
  const classes = useStyles()
  return <Paper className={classes.form}>
    <Typography component='h2' variant='h4' >Información básica</Typography>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Nombre' value={values.Name}
        onChange={e => onFormChange('Name', e.target.value)}
        placeholder={defaultValues.Name} />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Descripción' value={values.Description}
        onChange={e => onFormChange('Description', e.target.value)}
        placeholder={defaultValues.Description}
      />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='VenueIndex' value={values.VenueIndex}
        onChange={e => onFormChange('VenueIndex', e.target.value)}
        placeholder={defaultValues.VenueIndex}
      />
    </div>

    <div className='field'>
      <TextField fullWidth margin='normal' label='Indice' value={values.Index}
        onChange={e => onFormChange('Index', e.target.value)}
        placeholder={defaultValues.Index}
      />
    </div>

    <ImageField
      id={id}
      label='Imagen del avatar'
      onChange={url => onFormChange('Picture', url)}
      placeholder={defaultValues.Picture}
      value={values.Picture}
      folder='venues'
    />
  </Paper>
}

export default BasicInfo