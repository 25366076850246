export const formValues = {
  Title: 'Hoppia - evento',
  Details: "Próximamente",
  EndDate: "2020-09-03T18:00",
  PosterURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  BannerLeftURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  BannerRightURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  BannerMainScreenURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  BannerSmallScreenURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  VerticalBannerL1: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  VerticalBannerL2: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  VerticalBannerL3: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  VerticalBannerR1: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  VerticalBannerR2: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  VerticalBannerR3: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  WelcomeBanner: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  MapURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",

  PasilloDeLaFama_PosterURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg",
  PasilloDeLaFama_VideoURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/Ultraknife.mp4",

  StreamURL: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/Ultraknife.mp4",
  StreamURL2: "https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/Ultraknife.mp4",
  StartDate: "2020-09-03T10:00",
  MaxPlayersPerRoom: 50,
  LimitReached: false,
  Venue: 0,
  Avatars: [],
  IsPrivate: true,
  PassCodeActive: false,
  PassCode: '',
  Rooms: []
}