import React from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { useStyles } from '../styles'


const BoutiqueBar = ({
  onCreateBoutique = () => null,
}) => {
  const classes = useStyles()

  return <Paper className={`${classes.paper} ${classes.barContainer}`}>
    <Button onClick={onCreateBoutique} variant='contained'>
      <Typography>Agregar boutique</Typography>
    </Button>
  </Paper>
}

export default BoutiqueBar