const routes = {
  'home': {
    url: '/',
  },
  'events': {
    url: '/events'
  },
  'editEvents': {
    url: '/events/editar'
  },
  'event': {
    url: '/event'
  },
  'avatars': {
    url: '/avatars'
  },
  'avatar': {
    url: '/avatar'
  },
  'venues': {
    url: '/venues'
  },
  'venue': {
    url: '/venue'
  },
  'addUser': {
    url: '/user'
  },
  'appSettings': {
    url: '/app-settings'
  },
  'news': {
    url: '/noticias'
  },
  'sigleNews': {
    url: '/noticia'
  },
  'islands': {
    url: '/islas'
  },
  'island': {
    url: '/isla'
  },
  'boutiques': {
    url: '/boutiques'
  },
  'users': {
    url: '/usuarios'
  },
  'user': {
    url: '/usuario'
  },
  'rooms': {
    url: '/salas'
  },
  'billboards': {
    url: '/billboards'
  },
  'videos': {
    url: '/videos'
  },
  'games': {
    url: '/juegos'
  },
  'siteNews': {
    url: '/noticias_sitio'
  },
}

export const getUrl = routeName => {
  let route = routes[routeName]
  return route ? route.url : '/'
}


/*
  'home': {
    url: '/',
  },
  'events': {
    url: '/eventos'
  },
  'event': {
    url: '/evento'
  },

*/