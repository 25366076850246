import React, { useState, useEffect } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { useStyles } from './styles'
import { generateString } from '../../utils/misc'

import Loader from '../../baseComponents/Loader'
import BoutiqueBar from './components/BoutiqueBar'
import BoutiqueForm from '../../forms/BoutiqueForm'
import BoutiqueTable from './components/Table'

import './index.css'

const Boutiques = props => {
  const [boutiques, setBoutiques] = useState([])
    , [boutique, setBoutique] = useState(null)
    , [isFetching, setIsFetching] = useState(true)
    , [isFormActive, setIsFormActive] = useState(false)
    , classes = useStyles()
  const { eventId } = props.match.params

  const onCreateBoutique = () => {
    setIsFormActive(true)
  }

  const onSelect = (item) => {
    setBoutique(item)
    setIsFormActive(true)
  }

  useEffect(() => {
    const boutiquesRef = fbDatabase.ref('BOUTIQUES').orderByChild('EventId').equalTo(eventId)

    boutiquesRef.on('value', snapshot => {
      let boutiqueSnapshot = snapshot.val()
        , boutiqueList = []
      if (!boutiqueSnapshot) {
        setIsFetching(false)
        return
      }

      for (let id in boutiqueSnapshot)
        boutiqueList.push({ ...boutiqueSnapshot[id], Id: id })

      boutiqueList = boutiqueList.sort((a, b) => a.Index - b.Index)
      setBoutiques(boutiqueList)
      setIsFetching(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetching) return <Loader />

  if (isFormActive) {
    let lastId = Boolean(boutique) ? boutique.Id : `${generateString(12)}${Date.now()}`
    return <Container maxWidth='xl'>
      <BoutiqueForm
        eventId={eventId}
        isNewBoutique={!boutique}
        id={lastId}
        boutique={boutique}
        onSubmit={() => setIsFormActive(false)}
        onCancel={() => setIsFormActive(false)} />
    </Container>
  }

  return <div id='events'>
    <BoutiqueBar
      onCreateBoutique={onCreateBoutique}
    />
    <Paper className={classes.paper}>
      {
        !boutiques.length &&
        <Typography>Crea una boutique</Typography>
      }
      {
        Boolean(boutiques.length) &&
        <BoutiqueTable rows={boutiques} onSelect={onSelect} />
      }
    </Paper>
  </div>
}

export default Boutiques