import React, { useEffect, useState } from 'react'
import {
  Dialog, DialogTitle, Select, MenuItem, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, Button
} from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useStyles } from './styles'
import { reorderIndex, getItemDrag } from './utils'


const SortBoutiques = props => {
  const {
    rows,
    map,
    boutiquesList,
    onBoutiqueAssigned,
    onSave,
  } = props
    , [boutiques, setBoutiques] = useState([])
    , [dialogOpen, setDialogOpen] = useState(false)
    , [boutiqueIndex, setBoutiqueIndex] = useState(0)
    , [boutiqueId, setBoutiqueId] = useState('')
    , classes = useStyles()

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const itemsSorted = reorderIndex(
      boutiques,
      result.source.index,
      result.destination.index
    ).map((boutique, index) => ({ ...boutique, Index: index + 1 }))
    setBoutiques(itemsSorted)
    onSave(itemsSorted)
  }

  const onOptionSelected = () => {
    onBoutiqueAssigned(boutiqueIndex, boutiqueId)
    setDialogOpen(false)
    setBoutiqueIndex('')
    setBoutiqueId('')
  }

  const onAssingBoutique = (index, id) => {
    setBoutiqueId(id)
    setBoutiqueIndex(index)
    setDialogOpen(true)
  }

  useEffect(() => {
    setBoutiques(rows)
  }, [rows])

  return <React.Fragment>
    <div className='boutiques__container'>
      <TableContainer className={`table ${classes.paper}`} component={Paper} >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Posición</TableCell>
              <TableCell>Estado</TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {boutiques.map((item, index) => (
                    <Draggable key={`${item.Id}_${index}`} draggableId={`${item.Id}_${index}`} index={index}>
                      {(provided, snapshot) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemDrag(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <TableCell scope='row'>
                            <Typography>{item.Title}</Typography>
                          </TableCell>
                          <TableCell>{item.Index}</TableCell>
                          <TableCell>
                            <Button onClick={() => onAssingBoutique(index, item.Unassigned ? 'empty' : item.Id)} variant='contained'>
                              {item.Unassigned ? 'Asignar' : 'Reasignar'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>

              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {
        Boolean(map) &&
        <img className='boutiques__container-map' src={map} alt={map} />
      }
    </div>
    <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
      <div className={`${classes.paper} ${classes.dialog}`}>
        <DialogTitle>Selecciona una Boutique</DialogTitle>
        <Select
          value={boutiqueId}
          onChange={e => { setBoutiqueId(e.target.value) }}
        >
          <MenuItem value='empty'>
            Vacio
          </MenuItem>
          {
            boutiquesList.map((boutique, index) => <MenuItem key={index} value={boutique.Id}>{boutique.Title}</MenuItem>)
          }
        </Select>
        {
          Boolean(boutiqueId) &&
          <Button onClick={onOptionSelected}>
            <Typography>Aceptar</Typography>
          </Button>
        }
      </div>
    </Dialog>
  </React.Fragment>
}

export default SortBoutiques
