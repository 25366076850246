import { makeStyles, } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  roomCard: {
    marginBottom: theme.spacing(4),
  },
  form: {
    padding: theme.spacing(3),
    flexDirection: 'column',
  },
  bannersForm: {
    marginTop: theme.spacing(3),
  },
  headerId: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(1),
  },
  formBtn: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(1)
  },
  btn: {
    color: 'whitesmoke',
    margin: theme.spacing(2),
  },
  imageFieldTitle: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  createBtn: {
    background: theme.palette.success.main,
  },
  cancelBtn: {
    background: theme.palette.error.main,
  },
  slider: {
    color: theme.palette.info.dark,
    marginTop: theme.spacing(6),
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: '15vw'
  },
}))
