import React from 'react'
import Title from './Title'
import { Paper, Typography } from '@material-ui/core'
import { useStyles } from '../styles'

const EventCards = props => {
  const classes = useStyles()
  const { events } = props
    , pastEvents = events.filter(event => Date.now() > (new Date(event.EndDate)).getTime())
    , currentEvents = events.filter(event => Date.now() < (new Date(event.StartDate)).getTime())

  return <Paper className={classes.paper}>
    <Title>{events.length} eventos</Title>
    <Typography>{pastEvents.length} finalizados</Typography>
    <Typography>{currentEvents.length} por iniciar</Typography>
  </Paper>
}

export default EventCards