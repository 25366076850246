import React, { useEffect, useState } from 'react'
import Users from './components/Table'
import { fbDatabase } from '../../services/firebase'

const User = props => {
  const [checkIns, setCheckIns] = useState([])

  useEffect(() => {
    const { id } = props.match.params
    const { eventId } = props.match.params
    const checkInRef = fbDatabase.ref(`CHECK_IN/${id}`).child(eventId)

    checkInRef.on('value', snapshot => {
      let checkInList = snapshot.val()
        , checkIns = []

      for (let checkIn in checkInList)
        checkIns.push({ ...checkInList[checkIn], checkIn })

      checkIns = checkIns.sort((a, b) => a.checkIn - b.checkIn)

      setCheckIns(checkIns)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div>
    <Users rows={checkIns} />
  </div>
}

export default User