import React, { useReducer, useEffect, useState } from 'react'
import { Button, Typography, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { initialState, reducer } from './formReducer'
import { formValues } from './defaultValues'
import { useStyles } from './styles'
import { fbDatabase } from '../../services/firebase'

import Header from './components/Header'
import BasicInfo from './components/BasicInfo'

import './VenueForm.css'

const VenueForm = ({
  id = '1111',
  isNewVenue = false,
  venue = null,
  onSubmit = () => null,
  onCancel
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
    , [error, setError] = useState(false)
    , [success, setSuccess] = useState(false)
    , classes = useStyles()
    , onFormChange = (type, value) => { dispatch({ type, value }) }
    , onSubmitVenue = () => {
      let payload = {
        Name: state.Name.length ? state.Name : formValues.Name,
        Description: state.Description.length ? state.Description : formValues.Description,
        Picture: state.Picture.length ? state.Picture : formValues.Picture,
        VenueIndex: state.VenueIndex.length ? state.VenueIndex : formValues.VenueIndex,
        Index: state.Index ? state.Index : formValues.Index
      }
      const venueRef = fbDatabase.ref('VENUES').child(id)
      venueRef.set({
        ...venue,
        ...payload
      })
        .then(res => {
          setSuccess(true)
          setTimeout(() => {
            onSubmit(payload)
          }, 2000);
        })
        .catch(err => {
          console.log(err)
          setError(true)
        })
    }

  useEffect(() => {
    if (venue) dispatch({ type: 'setVenue', venue: venue })
  }, [venue])

  return <form onSubmit={onSubmitVenue} id='venue-form'>
    <Header isNewVenue={isNewVenue} id={id} />

    <BasicInfo id={id} defaultValues={formValues} isNewVenue={isNewVenue}
      onFormChange={onFormChange} values={state} />

    <Button className={`${classes.formBtn} ${classes.createBtn}`} onClick={onSubmitVenue} variant='contained' color='primary'>
      <Typography>
        {isNewVenue ? 'Crear' : 'Actualizar'}
      </Typography>
    </Button>

    {
      typeof onCancel === 'function'
        ? <Button className={`${classes.formBtn} ${classes.cancelBtn}`} onClick={onCancel} variant='contained' color='primary'>
          <Typography>Cancelar</Typography>
        </Button>
        : null
    }
    <Snackbar className={classes.success} open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
      <Alert elevation={6} variant="filled" severity="success">
        <Typography>Cambios guardados</Typography>
      </Alert>
    </Snackbar>
    <Snackbar className={classes.error} open={error} autoHideDuration={3000} onClose={() => setError(false)}>
      <Alert elevation={6} variant="filled" severity="error">
        <Typography>Algo salio mal</Typography>
      </Alert>
    </Snackbar>
  </form>
}

export default VenueForm