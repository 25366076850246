import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,
} from '@material-ui/core'


import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

const useStyles2 = makeStyles({
  table: { minWidth: 500 },
})

const TablePaginationActions = props => {
  const classes = useStyles1()
    , theme = useTheme()
    , { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0)
    , handleBackButtonClick = (event) => onPageChange(event, page - 1)
    , handleNextButtonClick = (event) => onPageChange(event, page + 1)
    , handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))

  return <div className={classes.root}>
    <IconButton
      onClick={handleFirstPageButtonClick}
      disabled={page === 0}
      aria-label="first page"
    >
      {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
    </IconButton>
    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
    </IconButton>
    <IconButton
      onClick={handleNextButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="next page"
    >
      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </IconButton>
    <IconButton
      onClick={handleLastPageButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="last page"
    >
      {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
    </IconButton>
  </div>
}

const UsersTable = ({ rows = [], }) => {
  const classes = useStyles2()
    , [page, setPage] = React.useState(0)
    , [rowsPerPage, setRowsPerPage] = React.useState(10)
    , emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
    , handleChangePage = (event, newPage) => setPage(newPage)
    , handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align='left' >Fecha de registro</TableCell>
            <TableCell align='left'>Sistema operativo</TableCell>
            <TableCell align='left'>Plataforma</TableCell>
            <TableCell align='left'>version</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => {
            return <TableRow key={row.Id}>
              <TableCell align='left'>
                {(new Date(Number(row.checkIn))).toLocaleString()}
              </TableCell>
              <TableCell align='left'>
                {row.os}
              </TableCell>
              <TableCell align='left'>
                {row.platform}
              </TableCell>
              <TableCell>
                {row.version}
              </TableCell>
            </TableRow>
          })}

          {
            emptyRows > 0 &&
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage='Usuarios por página'
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default UsersTable