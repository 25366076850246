export const initialState = {
  EventId: '',
  Available: false,
  Title: `Boutique`,
  IdEcommerce: '',
  Size: 'S',
  Logo: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
  Roof: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  LeftPoster: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  RightPoster: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  MainPoster: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  SmallSideboard1: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  SmallSideboard2: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  SmallSideboard3: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpgg'
  },
  SmallSideboard4: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  SmallSideboard5: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  SmallSideboard6: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  BigSideboard1: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
  BigSideboard2: {
    HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
    SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
  },
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'Title': return { ...state, Title: action.value }
    case 'IdEcommerce': return { ...state, IdEcommerce: action.value }
    case 'Size': return { ...state, Size: action.value }
    case 'Logo': return { ...state, Logo: action.value }
    case 'Roof': return { ...state, Roof: action.value }
    case 'LeftPoster': return { ...state, LeftPoster: action.value }
    case 'RightPoster': return { ...state, RightPoster: action.value }
    case 'MainPoster': return { ...state, MainPoster: action.value }
    case 'SmallSideboard1': return { ...state, SmallSideboard1: action.value }
    case 'SmallSideboard2': return { ...state, SmallSideboard2: action.value }
    case 'SmallSideboard3': return { ...state, SmallSideboard3: action.value }
    case 'SmallSideboard4': return { ...state, SmallSideboard4: action.value }
    case 'SmallSideboard5': return { ...state, SmallSideboard5: action.value }
    case 'SmallSideboard6': return { ...state, SmallSideboard6: action.value }
    case 'BigSideboard1': return { ...state, BigSideboard1: action.value }
    case 'BigSideboard2': return { ...state, BigSideboard2: action.value }
    case 'Available': return { ...state, Available: action.value }
    case 'setBoutique': return { ...state, ...action.boutique }
    default: return state
  }
}

/*

if (isNewEvent) {
  for (let index = 1; index <= state.Boutiques; index++) {
    let imagePayload = {
      SD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
      HD: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg'
    }

    let boutiquePayload = {
      Id: `${id}_${index}`,
      Index: index,
      Title: `${payload.Title}_${index}`,
      IdEcommerce: '',
      Size: 'S',
      Logo: 'https://hoppia.s3-us-west-2.amazonaws.com/events/2020/09/event_hoppia.jpg',
      Roof: imagePayload,
      LeftPoster: imagePayload,
      RightPoster: imagePayload,
      MainPoster: imagePayload,
      SmallSideboard1: imagePayload,
      SmallSideboard2: imagePayload,
      SmallSideboard3: imagePayload,
      SmallSideboard4: imagePayload,
      SmallSideboard5: imagePayload,
      SmallSideboard6: imagePayload,
      BigSideboard1: imagePayload,
      BigSideboard2: imagePayload,
      IslandId: id,
    }
    let boutiqueRef = fbDatabase.ref('BOUTIQUES').child(boutiquePayload.Id)
    await boutiqueRef.set(boutiquePayload)
  }
}

*/