import React, { useState, useEffect } from 'react'
import { Paper } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { fbDatabase } from '../../services/firebase'
import { getUrl } from '../../utils/routes'
import { useStyles } from './styles'
import { generateString } from '../../utils/misc'

import Loader from '../../baseComponents/Loader'
import IslandBar from './components/IslandBar'
import IslandForm from '../../forms/IslandForm'
import IslandTable from './components/Table'

import './index.css'

const Islands = props => {
  const [islands, setIslands] = useState([])
    , [isFetching, setIsFetching] = useState(true)
    , [isIslandFormActive, setIsIslandFormActive] = useState(false)
    , [island, setIsland] = useState(null)
    , classes = useStyles()

  const { eventId } = props.match.params

  const onEditList = () => {
    props.history.push(getUrl('editEvents'))
  }

  const onSelect = island => {
    setIsIslandFormActive(true)
    setIsland(island)
  }

  useEffect(() => {
    const islandRef = fbDatabase.ref('ISLANDS').orderByChild('EventId').equalTo(eventId)
    islandRef.on('value', snapshot => {
      let islandSnapshot = snapshot.val()
        , islandList = []

      for (let id in islandSnapshot)
        islandList.push({ id, ...islandSnapshot[id] })

      islandList = islandList.sort((a, b) => a.Created_At - b.Created_At)

      setIslands(islandList)
      setIsFetching(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetching) return <Loader />

  if (isIslandFormActive) {
    let lastId = Boolean(island) ? island.id : `${generateString(8)}${Date.now()}`

    return <Container maxWidth='xl'>
      <IslandForm
        eventId={eventId}
        isNewIsland={!island}
        id={lastId}
        island={island}
        onSubmit={() => {
          setIsland(null)
          setIsIslandFormActive(false)
        }}
        onCancel={() => {
          setIsland(null)
          setIsIslandFormActive(false)
        }} />
    </Container>
  }


  return <div id='events'>
    <IslandBar onShowForm={() => setIsIslandFormActive(true)} onEditList={onEditList} />
    <Paper className={classes.paper}>
      <IslandTable rows={islands} onSelect={onSelect} />
    </Paper>
  </div>
}

export default Islands