import React, { useState, useEffect } from 'react'
import {
  Button, Modal, Paper, Typography,
} from '@material-ui/core'
import TextField from '../../../baseComponents/TextField'
import Loader from '../../../baseComponents/Loader'
import { useStyles } from '../styles'

import AWS from 'aws-sdk'
const albumBucketName = 'hoppia'
  , bucketRegion = 'us-west-2'
  , IdentityPoolId = 'us-west-2:834dab99-0e60-4b82-a1bc-417547808931'


AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
  })
})

let s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: albumBucketName }
})

const ImageField = ({
  id,
  label,
  onChange,
  placeholder,
  value,
}) => {
  const classes = useStyles()
    , [file, setFile] = useState(null)
    , [url, setUrl] = useState('')
    , [isSending, setIsSending] = useState(false)
    , [isInputModal, setIsInputModal] = useState(false)
    , [isFileModal, setIsFileModal] = useState(false)
    , onFileSelected = e => {
      setFile(e.target.files[0])
    }
    , onUploadFile = () => {
      setIsSending(true)
      s3.upload({
        Key: `islands/${id}/${file.name}`,
        Body: file,
        ACL: 'public-read'
      }, (err, data) => {
        if (!err) {
          setFile(null)
          setIsFileModal(false)
          onChange(data.Location)
        }
        setIsSending(false)
      })
    }
    , onLoadFileBtn = () => setIsFileModal(!isFileModal)
    , onSetInputBtn = () => setIsInputModal(!isInputModal)
    , onSaveChangesField = () => {
      setIsInputModal(!isInputModal)
      onChange(url)
    }

  useEffect(() => {
    setUrl(value)
  }, [value])

  let filename = file ? `${file.name}` : ''

  return <React.Fragment>
    <div className='image-field-container'>
      <Typography className={classes.imageFieldTitle} component='h5' variant='h5' >{label}</Typography>
      <img src={value} alt={label} />
      <div className='image-field-options'>
        <Button onClick={onLoadFileBtn} className={`image-button ${classes.btn}`} variant='outlined' component='label'>
          <Typography>Cargar archivo</Typography>
        </Button>
        <Button onClick={onSetInputBtn} className={`image-button ${classes.btn}`} variant='outlined' component='label'>
          <Typography>Modificar URL</Typography>
        </Button>
      </div>
    </div>
    <Modal className={classes.modal} open={isFileModal} onClose={onLoadFileBtn}>
      <Paper className={`image-field-input ${classes.form}`}>
        <img src={file ? window.URL.createObjectURL(file) : placeholder} alt={label} />
        {
          isSending
            ? <div style={{ flexGrow: 1, }}><Loader text='Subiendo...' /></div>
            : null
        }
        <div className='image-field-buttons'>
          <Button className={`image-button ${classes.btn}`} variant='outlined' component='label'>
            <Typography>Cargar archivo</Typography>
            <input onChange={onFileSelected} type='file'
              accept='image/x-png,image/gif,image/jpeg,image/png'
              style={{ display: 'none' }} />
          </Button>
          {
            file
              ? <React.Fragment>
                <Typography className={`image-button ${classes.btn}`}>{filename.length > 40 ? `${filename.substring(0, 37)}...` : filename}</Typography>
                <Button onClick={onUploadFile} variant='outlined'
                  className={`image-button ${classes.btn}`}>
                  <Typography>Subir archivo</Typography>
                </Button>
              </React.Fragment>
              : null
          }
        </div>
      </Paper>
    </Modal>
    <Modal className={classes.modal} open={isInputModal} onClose={onSetInputBtn}>
      <Paper className={`image-field-input ${classes.form}`}>
        <img src={url} alt={label} />
        <TextField fullWidth margin='normal' label={label} value={url}
          onChange={e => setUrl(e.target.value)}
          placeholder={placeholder} />
        <Button onClick={onSaveChangesField} className={`image-button ${classes.btn}`} variant='outlined' component='label'>
          <Typography>Guardar cambios</Typography>
        </Button>
      </Paper>
    </Modal>

  </React.Fragment>
}

export default ImageField
