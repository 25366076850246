import React from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import { Typography, Paper } from '@material-ui/core'

const Header = ({ mainTitle = '', titleNewEvent = '', titleUpdateEvent = '', isNewEvent = true, id = '' }) => {
  const clasess = useStyles()
  let title = mainTitle
  if (!mainTitle.length) title = isNewEvent ? titleNewEvent : titleUpdateEvent

  return <div className='header'>
    <Typography component='h2' variant='h4' >{title}</Typography>
    <Paper className={clasess.headerId}>
      <Typography component='h5'>ID: {id}</Typography>
    </Paper>
  </div>
}

export default Header


const useStyles = makeStyles(theme => ({
  headerId: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(1),
  },
}))