import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { getUrl } from './utils/routes'


import AuthProvider from './providers/AuthProvider'
import EventInfoProvider from './providers/EventInfoProvider'

import Layout from './baseComponents/Layout'

import AppSettings from './screens/AppSettings'
import SiteNews from './screens/SiteNews'
import Avatar from './screens/Avatar'
import Avatars from './screens/Avatars'
import Events from './screens/Events'
import Home from './screens/Home'
import Venue from './screens/Venue'
import Venues from './screens/Venues'

import BillBoards from './screens/Billboards'
import Videos from './screens/Videos'
import Agenda from './screens/Agenda'
import News from './screens/News'
import EventUsers from './screens/EventUsers'
import Islands from './screens/Islands'
import Boutiques from './screens/Boutiques'
import EditEvents from './screens/EditEvents'
import Games from './screens/Games'
import Event from './screens/Event'
import Rooms from './screens/Rooms'
import User from './screens/User'

import './App.css'

const THEME = createTheme({
  palette: {
    type: 'dark',
    primary: { light: '#482880', main: '#673ab7', dark: '#8561c5', },
    secondary: { light: '#b2102f', main: '#ff1744', dark: '#ff4569', }
  }
})

const App = () => {
  return <ThemeProvider theme={THEME}>
    <CssBaseline />
    <AuthProvider>
      <Switch>
        <Layout>
          <Route exact path={getUrl('siteNews')} component={SiteNews} />
          <Route exact path={getUrl('appSettings')} component={AppSettings} />
          <Route exact path={getUrl('avatars')} component={Avatars} />
          <Route exact path={`${getUrl('avatar')}/:id`} component={Avatar} />
          <Route exact path={getUrl('editEvents')} component={EditEvents} />
          <Route exact path={getUrl('home')} component={Home} />
          <Route exact path={`${getUrl('venue')}/:id`} component={Venue} />
          <Route exact path={getUrl('venues')} component={Venues} />
          <Route exact path={getUrl('events')} component={Events} />
          <Route path={`${getUrl('event')}/:eventId`}>
            <EventInfoProvider>
              <Route exact path={`${getUrl('event')}/:eventId`} component={Event} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('billboards')}`} component={BillBoards} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('users')}`} component={EventUsers} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('islands')}`} component={Islands} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('rooms')}`} component={Rooms} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('user')}/:id`} component={User} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('videos')}`} component={Videos} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('events')}`} component={Agenda} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('news')}`} component={News} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('boutiques')}`} component={Boutiques} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('games')}`} component={Games} />
              <Route exact path={`${getUrl('event')}/:eventId${getUrl('venue')}`} component={Venue} />
            </EventInfoProvider>
          </Route>
        </Layout>
      </Switch>
    </AuthProvider>
  </ThemeProvider>
}

export default App
