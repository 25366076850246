import React, { useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Paper, Table, TableBody, TableCell, TableContainer,
  TableFooter, TableHead, TablePagination, TableRow, Button,
  Typography
} from '@material-ui/core'

import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}))

const useStyles2 = makeStyles({
  table: { minWidth: 500 },
})

const TablePaginationActions = props => {
  const classes = useStyles1()
    , theme = useTheme()
    , { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0)
    , handleBackButtonClick = (event) => onPageChange(event, page - 1)
    , handleNextButtonClick = (event) => onPageChange(event, page + 1)
    , handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))

  return <div className={classes.root}>
    <IconButton
      onClick={handleFirstPageButtonClick}
      disabled={page === 0}
      aria-label="first page"
    >
      {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
    </IconButton>
    <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
      {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
    </IconButton>
    <IconButton
      onClick={handleNextButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="next page"
    >
      {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
    </IconButton>
    <IconButton
      onClick={handleLastPageButtonClick}
      disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      aria-label="last page"
    >
      {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
    </IconButton>
  </div>
}

const UsersTable = ({ rows = [], lastModified, onSetUser = () => null }) => {
  const classes = useStyles2()
    , [page, setPage] = React.useState(0)
    , [elements, setElements] = React.useState([])
    , [filter, setFilter] = React.useState('points-desc')
    , [rowsPerPage, setRowsPerPage] = React.useState(10)
    , emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
    , handleChangePage = (event, newPage) => setPage(newPage)
    , handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

  const onClickFilter = newFilter => {
    let sortType = 'desc'
      , lastFilter = filter.split('-')

    if (newFilter === lastFilter[0]) sortType = lastFilter[1] === 'desc' ? 'asc' : 'desc'
    setElements([])
    setFilter(`${newFilter}-${sortType}`)
    setPage(0)
  }

  const getStyles = key => {
    let filterKey = filter.split('-')[0]
    return {
      backgroundColor: key === filterKey ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }
  }

  const getIcon = key => {
    let filterKey = filter.split('-')
    if (filterKey[0] === key) return filterKey[1] === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
    return <FilterListIcon />
  }

  useEffect(() => {
    let elements = []
    switch (filter) {
      case 'points-desc': { elements = rows.sort((a, b) => Number(b.Points) - Number(a.Points)); break; }
      case 'points-asc': { elements = rows.sort((a, b) => Number(a.Points) - Number(b.Points)); break; }
      case 'name-desc': { elements = rows.sort((a, b) => a.Name.localeCompare(b.Name)); break; }
      case 'name-asc': { elements = rows.sort((a, b) => b.Name.localeCompare(a.Name)); break; }
      case 'date-asc': { elements = rows.sort((a, b) => new Date(b.Date) - new Date(a.Date)); break; }
      case 'date-desc': { elements = rows.sort((a, b) => new Date(a.Date) - new Date(b.Date)); break; }
      default: { elements = rows.sort((a, b) => Number(b.Points) - Number(a.Points)); break; }
    }
    setElements(elements)
  }, [rows, filter])

  return <TableContainer component={Paper}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align='left'>ID</TableCell>
          <TableCell align='left'>
            <Button style={{ ...getStyles('name') }} onClick={() => onClickFilter('name')}>
              <Typography>Nombre</Typography>
              {getIcon('name')}
            </Button>
          </TableCell>
          <TableCell align='left'>
            <Button style={{ ...getStyles('points') }} onClick={() => onClickFilter('points')}>
              <Typography>Puntos</Typography>
              {getIcon('points')}
            </Button>
          </TableCell>
          <TableCell align='left'>
            <Button style={{ ...getStyles('date') }} onClick={() => onClickFilter('date')}>
              <Typography>Fecha</Typography>
              {getIcon('date')}
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(rowsPerPage > 0
          ? elements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : elements
        ).map((row) => {
          return <TableRow key={row.Id}>
            <TableCell align='left'>
              {row.Id}
            </TableCell>
            <TableCell align='left'>
              {row.Name}
            </TableCell>
            <TableCell align='left'>
              {row.Points}
            </TableCell>
            <TableCell>
              {(new Date(row.Date)).toLocaleString()}
            </TableCell>
          </TableRow>
        })}

        {
          emptyRows > 0 &&
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
            colSpan={3}
            count={elements.length}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage='Usuarios por página'
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>
}

export default UsersTable