import React from 'react'
import { Button, Paper, Typography } from '@material-ui/core'
import { useStyles } from '../styles'


const VenueBar = ({
  onShowForm
}) => {
  const classes = useStyles()

  return <Paper className={classes.paper}>
    <Button onClick={onShowForm} className={classes.addEventBtn} variant='outlined'>
      <Typography>Nuevo venue</Typography>
    </Button>
  </Paper>
}

export default VenueBar